import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMembership } from "../context/membershipContext";
import { getAvailableMemberTypes } from "../api/membershipApi";
import { UsAndTerritoriesId } from "../data/data";
import { getAllSchools } from "../api/membershipApi";
import { isNavigationValid } from "../utils/functions";
import userManager from "../services/userService";

import Spinner from "../components/ui/Spinner";
import TopNav from "../components/ui/TopNav";
import ScrollToTop from "../components/ui/ScrollToTop";
import Header from "../components/ui/Header";

const MemberType = (prop) => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  const [schools, setSchools] = useState([]);
  const npTypes = [
    "Career Starter Member",
    "NP Member",
    "Post Master's Student Member",
  ];

  //console.log("membership", membership);

  //If available membertypes don't exist, hit api to get them; if no error types are returned reroute to error page
  useEffect(() => {
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    async function getMembershipTypes() {
      //Code here is for cases when someone changes from domestic to international address during the process
      const isInternationalAddress = !UsAndTerritoriesId.some(
        (us) => us.countryID === membership.address.countryID
      );
      if (
        (isInternationalAddress && !membership.memberType.isInternational) ||
        (!isInternationalAddress && membership.memberType.isInternational)
      ) {
        let updatedMembership = membership;
        updatedMembership.memberType = _getMemberType();
        dispatch({
          type: "updateMembership",
          updatedMembership,
        });
      }

      if (
        membership.memberTypes.length === 0 ||
        (!isInternationalAddress && membership.memberTypes.length <= 2) ||
        (isInternationalAddress && membership.memberTypes.length > 2)
      ) {
        setIsSearching(true);
        let accesstoken = membership.accessToken;
        if (accesstoken === "" && membership.personBasic.id > 0) {
          const user = await userManager.signinRedirectCallback();
          if (typeof user === "undefined") {
            history.push("/error");
          } else {
            accesstoken = user.access_token;
          }
        }

        getAvailableMemberTypes(
          membership.personBasic.id,
          membership.address.countryID,
          accesstoken
        ).then((response) => {
          if (response.success) {
            dispatch({
              type: "updateMemberTypes",
              memberTypes: response.list,
            });
          } else {
            history.push("/error");
          }
          setIsSearching(false);
        });
      }
    }
    getMembershipTypes();
  }, []);

  useEffect(() => {
    const storedSchools = sessionStorage.getItem("aanp_schools");
    if (!storedSchools) {
      setIsSearching(true);
      async function getSchools() {
        getAllSchools().then((response) => {
          if (response.success) {
            setSchools(response.list);
            sessionStorage.setItem(
              "aanp_schools",
              JSON.stringify(response.list)
            );
          } else {
            history.push("/error");
          }
        });
      }
      getSchools();
    } else {
      if (schools.length === 0) {
        setSchools(JSON.parse(storedSchools));
      }
    }
  }, []);

  //Returns the values of the membership type selected on the form; if no name is provided, it returns a blank member type object
  function _getMemberType(name) {
    if (name) {
      if (membership.memberTypes.length > 0) {
        return membership.memberTypes.find(
          (mt) => mt.name.toLowerCase() === name.toLowerCase()
        );
      }
    }
    return {
      id: 0,
      displayName: "",
      name: "",
      price: 0,
      disabledForUser: false,
      eligibleForAutoRenewal: false,
    };
  }

  //Validation function to make sure member types have been selected correctly
  function _isPageComplete() {
    const careerStarterComplete = () => {
      let careerStarterAvailable = membership.memberTypes.some(
        (mt) => mt.name === "Career Starter Member"
      );
      const careerStarterChosen =
        membership.memberType.name === "Career Starter Member";

      return (
        membership.isCareerStarter !== "" ||
        careerStarterChosen ||
        !careerStarterAvailable
      );
    };

    const licensedNpComplete = () => {
      let licensedNpAvailable = membership.memberTypes.some(
        (mt) => mt.name === "Post Master's Student Member"
      );
      const licensedNpChosen =
        membership.memberType.name === "Post Master's Student Member";

      return (
        membership.isLicensedNpStudent !== "" ||
        licensedNpChosen ||
        !licensedNpAvailable
      );
    };

    const isStudentComplete = () => {
      if (
        membership.memberType.name === "Student Member" ||
        membership.memberType.name === "International Student Member"
      ) {
        if (
          !membership.student.undergradAttested ||
          membership.student.schoolName === ""
        ) {
          return false;
        }
      }
      return true;
    };

    return (
      (npTypes.includes(membership.memberType.name) &&
        careerStarterComplete() &&
        licensedNpComplete()) ||
      (!npTypes.includes(membership.memberType.name) &&
        isStudentComplete() &&
        parseInt(membership.memberType.id, 10) > 0)
    );
  }

  //Stock function to set app storage values based on user input; a little extra logic to make sure set values
  //of NP sub-types on the form
  function _setMemberType(e) {
    const value = e.target.value;
    const name = e.target.name;
    let updatedMembership = membership;

    //console.log("name", name);

    switch (name) {
      case "career_starter":
        if (value === "true") {
          updatedMembership.isCareerStarter = "true";
          updatedMembership.isLicensedNpStudent = "false";
          updatedMembership.memberType = _getMemberType(
            "Career Starter Member"
          );
        } else {
          updatedMembership.isCareerStarter = "false";
          updatedMembership.memberType = _getMemberType("NP Member");
        }
        break;

      case "licensed_program":
        if (value === "true") {
          updatedMembership.isCareerStarter = "false";
          updatedMembership.isLicensedNpStudent = "true";
          updatedMembership.memberType = _getMemberType(
            "Post Master's Student Member"
          );
        } else {
          updatedMembership.isLicensedNpStudent = "false";
          updatedMembership.memberType = _getMemberType("NP Member");
        }
        break;

      case "schoolName":
      case "intlSchoolName":
        //console.log("intl name");
        updatedMembership.student.schoolName = value;
        break;

      case "undergradAttested":
      case "intlUndergradAttested":
        updatedMembership.student.undergradAttested = e.target.checked;
        break;

      default:
        updatedMembership.isCareerStarter = "";
        updatedMembership.isLicensedNpStudent = "";
        updatedMembership.student.undergradAttested = false;
        updatedMembership.student.schoolName = "";
        updatedMembership.memberType = _getMemberType(value);
        break;
    }

    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  //Navigate either forward to discounts, or backward to contact-info
  //Save membership info to session storage before navigating
  function _navigateToStep(step) {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    history.push(step, { from: pathname, propState: "" });
  }

  return (
    <>
      <ScrollToTop />
      <Header isMember={membership.personBasic.isMember} />
      <div
        className="grid-container join-content"
        style={{ paddingTop: "10px" }}
      >
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <TopNav
                  currentStep="member-type"
                  backwardStepDisabled={membership.personIsComplete}
                  forwardStepDisabled={!_isPageComplete()}
                  handleNavigate={_navigateToStep}
                  membership={membership}
                />

                <div className="box-content">
                  <div className="text-center">
                    <div className="box-content-intro">
                      <h2>Now determine your membership type.</h2>
                      <span className="helper">
                        * Please select an option below.
                      </span>
                    </div>
                  </div>
                  <>
                    <div className="box-section">
                      <h3 className="box-section-heading">
                        Membership Information
                      </h3>
                      <div className="grid-x grid-margin-x">
                        <div
                          className="cell medium-10"
                          style={{ minHeight: "390px" }}
                        >
                          {isSearching ? (
                            <Spinner />
                          ) : (
                            <form>
                              <div style={{ minHeight: "300px" }}>
                                {membership.memberTypes.some(
                                  (mt) => mt.name === "NP Member"
                                ) && (
                                  <div className="radioFancy radioFancy--expanded">
                                    <input
                                      required
                                      name="NP"
                                      data-show="role"
                                      type="radio"
                                      className="radioFancy-input"
                                      id="NP"
                                      value="NP Member"
                                      checked={npTypes.includes(
                                        membership.memberType.name
                                      )}
                                      onChange={_setMemberType}
                                    />
                                    <label
                                      htmlFor="NP"
                                      className="radioFancy-label"
                                    >
                                      I am a licensed and/or certified Nurse
                                      Practitioner.
                                    </label>
                                  </div>
                                )}
                                {npTypes.includes(membership.memberType.name) &&
                                  (membership.memberTypes.some(
                                    (mt) => mt.name === "Career Starter Member"
                                  ) ||
                                    membership.memberTypes.some(
                                      (mt) =>
                                        mt.name ===
                                        "Post Master's Student Member"
                                    )) && (
                                    <>
                                      {membership.memberTypes.some(
                                        (mt) =>
                                          mt.name ===
                                          "Post Master's Student Member"
                                      ) && (
                                        <fieldset className="input-wrapper input-wrapper--indented">
                                          <div className="grid-x grid-margin-x">
                                            <div className="cell large-8">
                                              <legend
                                                htmlFor="program"
                                                className="labelHeading"
                                                style={{
                                                  marginBottom: "0rem",
                                                }}
                                              >
                                                Are you in an NP program?
                                              </legend>
                                              {/* <button
                                                onClick={_openNpProgramModal}
                                                className="modal-link"
                                              >
                                                Tell me more about this.
                                              </button> */}
                                            </div>
                                            <div className="cell large-4">
                                              <div className="grid-x grid-margin-x">
                                                <div className="cell medium-6 small-4">
                                                  <input
                                                    type="radio"
                                                    name="licensed_program"
                                                    id="program_yes"
                                                    value="true"
                                                    checked={
                                                      membership.isLicensedNpStudent ===
                                                      "true"
                                                    }
                                                    onChange={_setMemberType}
                                                  />
                                                  <label htmlFor="program_yes">
                                                    Yes
                                                  </label>
                                                </div>
                                                <div className="cell medium-6 small-4">
                                                  <input
                                                    type="radio"
                                                    name="licensed_program"
                                                    id="program_no"
                                                    value="false"
                                                    checked={
                                                      membership.isLicensedNpStudent ===
                                                      "false"
                                                    }
                                                    onChange={_setMemberType}
                                                  />
                                                  <label htmlFor="program_no">
                                                    No
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </fieldset>
                                      )}

                                      {membership.memberTypes.some(
                                        (mt) =>
                                          mt.name === "Career Starter Member"
                                      ) && (
                                        <fieldset className="input-wrapper input-wrapper--indented">
                                          <div className="grid-x grid-margin-x">
                                            <div className="cell large-8">
                                              <legend
                                                htmlFor="firstyear"
                                                className="labelHeading"
                                              >
                                                Are you in your first year of
                                                practice?
                                              </legend>
                                            </div>
                                            <div className="cell large-4">
                                              <div className="grid-x grid-margin-x">
                                                <div className="cell medium-6 small-4">
                                                  <input
                                                    type="radio"
                                                    name="career_starter"
                                                    id="career_starter_true"
                                                    value="true"
                                                    checked={
                                                      membership.isCareerStarter ===
                                                      "true"
                                                    }
                                                    onChange={_setMemberType}
                                                  />
                                                  <label htmlFor="career_starter_true">
                                                    Yes
                                                  </label>
                                                </div>
                                                <div className="cell medium-6 small-4">
                                                  <input
                                                    type="radio"
                                                    name="career_starter"
                                                    id="career_starter_no"
                                                    value="false"
                                                    checked={
                                                      membership.isCareerStarter ===
                                                      "false"
                                                    }
                                                    onChange={_setMemberType}
                                                  />
                                                  <label htmlFor="career_starter_no">
                                                    No
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </fieldset>
                                      )}
                                    </>
                                  )}
                                {membership.memberTypes.some(
                                  (mt) => mt.name === "Student Member"
                                ) && (
                                  <>
                                    <div className="radioFancy radioFancy--expanded">
                                      <input
                                        required
                                        name="Student"
                                        data-show="role"
                                        type="radio"
                                        className="radioFancy-input"
                                        id="Student"
                                        value="Student Member"
                                        checked={
                                          membership.memberType.name ===
                                          "Student Member"
                                        }
                                        onChange={_setMemberType}
                                      />
                                      <label
                                        htmlFor="Student"
                                        className="radioFancy-label"
                                      >
                                        I am currently a Student.
                                      </label>
                                    </div>
                                    {membership.memberType.name ===
                                      "Student Member" && (
                                      <>
                                        {isSearching ? (
                                          <Spinner />
                                        ) : (
                                          <>
                                            <fieldset className="input-wrapper input-wrapper--indented">
                                              <div className="grid-x grid-margin-x">
                                                <div className="cell large-6">
                                                  <legend
                                                    htmlFor="schoolName"
                                                    className="labelHeading"
                                                    style={{
                                                      marginBottom: "0.5rem",
                                                    }}
                                                  >
                                                    What school are you
                                                    attending?
                                                  </legend>
                                                </div>
                                                <div className="cell large-6">
                                                  <div className="grid-x grid-margin-x">
                                                    <div className="cell large-12">
                                                      <select
                                                        id="schoolName"
                                                        name="schoolName"
                                                        aria-required="true"
                                                        className=""
                                                        value={
                                                          membership.student
                                                            .schoolName
                                                        }
                                                        onChange={
                                                          _setMemberType
                                                        }
                                                      >
                                                        <option value="0">
                                                          Please Select a School
                                                        </option>
                                                        {schools.map((s) => {
                                                          return (
                                                            <option
                                                              key={s.schoolID}
                                                              value={
                                                                s.schoolName
                                                              }
                                                            >
                                                              {s.schoolName}
                                                            </option>
                                                          );
                                                        })}
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="grid-x grid-margin-x">
                                                <div className="cell large-12">
                                                  <input
                                                    type="checkbox"
                                                    name="undergradAttested"
                                                    id="undergradAttested"
                                                    checked={
                                                      membership.student
                                                        .undergradAttested
                                                    }
                                                    onChange={_setMemberType}
                                                    value={
                                                      membership.student
                                                        .undergradAttested
                                                    }
                                                  />{" "}
                                                  <label
                                                    htmlFor="undergradAttested"
                                                    className="labelCheckbox"
                                                  >
                                                    I attest that I am currently
                                                    enrolled in an entry-level
                                                    NP program.
                                                  </label>
                                                  <input type="checkbox"></input>
                                                </div>
                                              </div>
                                            </fieldset>{" "}
                                          </>
                                        )}
                                      </>
                                    )}{" "}
                                  </>
                                )}

                                {membership.memberTypes.some(
                                  (mt) => mt.name === "Retired Member"
                                ) && (
                                  <div className="radioFancy radioFancy--expanded">
                                    <input
                                      required
                                      name="Retired"
                                      data-show="role"
                                      type="radio"
                                      className="radioFancy-input"
                                      id="Retired"
                                      value="Retired Member"
                                      checked={
                                        membership.memberType.name ===
                                        "Retired Member"
                                      }
                                      onChange={_setMemberType}
                                    />
                                    <label
                                      htmlFor="Retired"
                                      className="radioFancy-label"
                                    >
                                      I am a retired Nurse Practitioner.
                                    </label>
                                  </div>
                                )}
                                {membership.memberTypes.some(
                                  (mt) => mt.name === "Associate Member"
                                ) && (
                                  <div className="radioFancy radioFancy--expanded">
                                    <input
                                      required
                                      name="Associate"
                                      data-show="role"
                                      type="radio"
                                      className="radioFancy-input"
                                      id="Associate"
                                      value="Associate Member"
                                      checked={
                                        membership.memberType.name ===
                                        "Associate Member"
                                      }
                                      onChange={_setMemberType}
                                    />
                                    <label
                                      htmlFor="Associate"
                                      className="radioFancy-label"
                                    >
                                      I am not an NP but support NPs.
                                    </label>
                                  </div>
                                )}
                                {membership.memberTypes.some(
                                  (mt) =>
                                    mt.name ===
                                    "International Advanced Practice Nurse"
                                ) && (
                                  <div className="radioFancy radioFancy--expanded">
                                    <input
                                      required
                                      name="InternationalNp"
                                      data-show="role"
                                      type="radio"
                                      className="radioFancy-input"
                                      id="InternationalNp"
                                      value="International Advanced Practice Nurse"
                                      checked={
                                        membership.memberType.name ===
                                        "International Advanced Practice Nurse"
                                      }
                                      onChange={_setMemberType}
                                    />
                                    <label
                                      htmlFor="InternationalNp"
                                      className="radioFancy-label"
                                    >
                                      I am an International Advanced Practice
                                      Nurse.
                                    </label>
                                  </div>
                                )}
                                {membership.memberTypes.some(
                                  (mt) =>
                                    mt.name === "International Student Member"
                                ) && (
                                  <>
                                    <div className="radioFancy radioFancy--expanded">
                                      <input
                                        required
                                        name="InternationalStudent"
                                        data-show="role"
                                        type="radio"
                                        className="radioFancy-input"
                                        id="InternationalStudent"
                                        value="International Student Member"
                                        checked={
                                          membership.memberType.name ===
                                          "International Student Member"
                                        }
                                        onChange={_setMemberType}
                                      />
                                      <label
                                        htmlFor="InternationalStudent"
                                        className="radioFancy-label"
                                      >
                                        I am an International Student Nurse.
                                      </label>
                                    </div>
                                    {membership.memberType.name ===
                                      "International Student Member" && (
                                      <fieldset className="input-wrapper input-wrapper--indented">
                                        <div className="grid-x grid-margin-x">
                                          <div className="cell large-6">
                                            <legend
                                              htmlFor="schoolName"
                                              className="labelHeading"
                                              style={{
                                                marginBottom: "0.5rem",
                                              }}
                                            >
                                              What school are you attending?
                                            </legend>
                                          </div>
                                          <div className="cell large-6">
                                            <div className="grid-x grid-margin-x">
                                              <div className="cell large-12">
                                                <p
                                                  className="input-wrapper"
                                                  style={{
                                                    marginBottom: "1.3rem",
                                                  }}
                                                >
                                                  {" "}
                                                  <input
                                                    id="intlSchoolName"
                                                    name="intlSchoolName"
                                                    aria-required="true"
                                                    className=""
                                                    type="text"
                                                    value={
                                                      membership.student
                                                        .schoolName
                                                    }
                                                    onChange={_setMemberType}
                                                  ></input>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                        <div className="grid-x grid-margin-x">
                                          <div className="cell large-12">
                                            <input
                                              type="checkbox"
                                              name="intlUndergradAttested"
                                              id="intlUndergradAttested"
                                              checked={
                                                membership.student
                                                  .undergradAttested
                                              }
                                              onChange={_setMemberType}
                                              value={
                                                membership.student
                                                  .undergradAttested
                                              }
                                            />{" "}
                                            <label
                                              htmlFor="intlUndergradAttested"
                                              className="labelCheckbox"
                                            >
                                              I attest that I am currently
                                              enrolled in an entry-level NP
                                              program.
                                            </label>
                                            <input type="checkbox"></input>
                                          </div>
                                        </div>{" "}
                                      </fieldset>
                                    )}
                                  </>
                                )}
                                {membership.memberTypes.length > 0 && (
                                  <div className="box-controls">
                                    <button
                                      className="button"
                                      type="button"
                                      disabled={!_isPageComplete()}
                                      onClick={() =>
                                        _navigateToStep("/discounts")
                                      }
                                    >
                                      Continue
                                    </button>
                                  </div>
                                )}
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberType;
