export const addressErrors = [
  "addressLine1",
  "city",
  "stateProvince",
  "postalCode",
  "countryID",
  "companyName",
  "city",
  "stateProvince",
  "postalCode",
  "companyName",
];

export const MemberTypes = {
  Associate: {
    id: 3,
    displayName: "Associate Member",
    price: 160,
  },
  CareerStarter: {
    id: 2,
    displayName: "Career Starter Member",
    price: 95,
  },
  LicensedNpStudent: {
    id: 8,
    displayName: "Licensed NP Student Member",
    price: 95,
  },
  Retired: {
    id: 4,
    displayName: "Retired Member",
    price: 55,
  },
  Student: {
    id: 7,
    displayName: "Student Member",
    price: 55,
  },
  NP: {
    id: 6,
    displayName: "NP Member",
    price: 150,
  },
};

export const AvailableDiscounts = {
  AutoRenew: {
    id: 35,
    amount: 0,
    displayName: "Auto Renewal Discount",
  },
  NPO: {
    id: 37,
    amount: 10,
    displayName: "NPO Discount",
  },
  Military: {
    id: 215,
    amount: 10,
    displayName: "Military Discount",
  },
};

export const UsAndTerritoriesId = [
  {
    countryName: "American Samoa",
    countryID: 12,
  },
  {
    countryName: "Guam",
    countryID: 88,
  },
  {
    countryName: "Northern Mariana Islands",
    countryID: 141,
  },
  {
    countryName: "United States",
    countryID: 222,
  },
  {
    countryName: "Virgin Islands, U.S.",
    countryID: 229,
  },
];

export const UsStates = [
  {
    countryID: 222,
    state: "Alabama",
    stateAbb: "AL",
  },
  {
    countryID: 222,
    state: "Alaska",
    stateAbb: "AK",
  },
  {
    countryID: 222,
    state: "American Samoa",
    stateAbb: "AS",
  },
  {
    countryID: 222,
    state: "Arizona",
    stateAbb: "AZ",
  },
  {
    countryID: 222,
    state: "Arkansas",
    stateAbb: "AR",
  },
  {
    countryID: 222,
    state: "California",
    stateAbb: "CA",
  },
  {
    countryID: 222,
    state: "Colorado",
    stateAbb: "CO",
  },
  {
    countryID: 222,
    state: "Connecticut",
    stateAbb: "CT",
  },
  {
    countryID: 222,
    state: "Delaware",
    stateAbb: "DE",
  },
  {
    countryID: 222,
    state: "District Of Columbia",
    stateAbb: "DC",
  },
  {
    countryID: 222,
    state: "Federated States Of Micronesia",
    stateAbb: "FM",
  },
  {
    countryID: 222,
    state: "Florida",
    stateAbb: "FL",
  },
  {
    countryID: 222,
    state: "Georgia",
    stateAbb: "GA",
  },
  {
    countryID: 222,
    state: "Guam",
    stateAbb: "GU",
  },
  {
    countryID: 222,
    state: "Hawaii",
    stateAbb: "HI",
  },
  {
    countryID: 222,
    state: "Idaho",
    stateAbb: "ID",
  },
  {
    countryID: 222,
    state: "Illinois",
    stateAbb: "IL",
  },
  {
    countryID: 222,
    state: "Indiana",
    stateAbb: "IN",
  },
  {
    countryID: 222,
    state: "Iowa",
    stateAbb: "IA",
  },
  {
    countryID: 222,
    state: "Kansas",
    stateAbb: "KS",
  },
  {
    countryID: 222,
    state: "Kentucky",
    stateAbb: "KY",
  },
  {
    countryID: 222,
    state: "Louisiana",
    stateAbb: "LA",
  },
  {
    countryID: 222,
    state: "Maine",
    stateAbb: "ME",
  },
  {
    countryID: 222,
    state: "Marshall Islands",
    stateAbb: "MH",
  },
  {
    countryID: 222,
    state: "Maryland",
    stateAbb: "MD",
  },
  {
    countryID: 222,
    state: "Massachusetts",
    stateAbb: "MA",
  },
  {
    countryID: 222,
    state: "Michigan",
    stateAbb: "MI",
  },
  {
    countryID: 222,
    state: "Minnesota",
    stateAbb: "MN",
  },
  {
    countryID: 222,
    state: "Mississippi",
    stateAbb: "MS",
  },
  {
    countryID: 222,
    state: "Missouri",
    stateAbb: "MO",
  },
  {
    countryID: 222,
    state: "Montana",
    stateAbb: "MT",
  },
  {
    countryID: 222,
    state: "Nebraska",
    stateAbb: "NE",
  },
  {
    countryID: 222,
    state: "Nevada",
    stateAbb: "NV",
  },
  {
    countryID: 222,
    state: "New Hampshire",
    stateAbb: "NH",
  },
  {
    countryID: 222,
    state: "New Jersey",
    stateAbb: "NJ",
  },
  {
    countryID: 222,
    state: "New Mexico",
    stateAbb: "NM",
  },
  {
    countryID: 222,
    state: "New York",
    stateAbb: "NY",
  },
  {
    countryID: 222,
    state: "North Carolina",
    stateAbb: "NC",
  },
  {
    countryID: 222,
    state: "North Dakota",
    stateAbb: "ND",
  },
  {
    countryID: 222,
    state: "Northern Mariana Islands",
    stateAbb: "MP",
  },
  {
    countryID: 222,
    state: "Ohio",
    stateAbb: "OH",
  },
  {
    countryID: 222,
    state: "Oklahoma",
    stateAbb: "OK",
  },
  {
    countryID: 222,
    state: "Oregon",
    stateAbb: "OR",
  },
  {
    countryID: 222,
    state: "Palau",
    stateAbb: "PW",
  },
  {
    countryID: 222,
    state: "Pennsylvania",
    stateAbb: "PA",
  },
  {
    countryID: 222,
    state: "Puerto Rico",
    stateAbb: "PR",
  },
  {
    countryID: 222,
    state: "Rhode Island",
    stateAbb: "RI",
  },
  {
    countryID: 222,
    state: "South Carolina",
    stateAbb: "SC",
  },
  {
    countryID: 222,
    state: "South Dakota",
    stateAbb: "SD",
  },
  {
    countryID: 222,
    state: "Tennessee",
    stateAbb: "TN",
  },
  {
    countryID: 222,
    state: "Texas",
    stateAbb: "TX",
  },
  {
    countryID: 222,
    state: "Utah",
    stateAbb: "UT",
  },
  {
    countryID: 222,
    state: "Vermont",
    stateAbb: "VT",
  },
  {
    countryID: 222,
    state: "Virgin Islands",
    stateAbb: "VI",
  },
  {
    countryID: 222,
    state: "Virginia",
    stateAbb: "VA",
  },
  {
    countryID: 222,
    state: "Washington",
    stateAbb: "WA",
  },
  {
    countryID: 222,
    state: "West Virginia",
    stateAbb: "WV",
  },
  {
    countryID: 222,
    state: "Wisconsin",
    stateAbb: "WI",
  },
  {
    countryID: 222,
    state: "Wyoming",
    stateAbb: "WY",
  },
];
