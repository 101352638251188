import React from "react";
import {
  //BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import Account from "./pages/Account";
import ContactInfo from "./pages/ContactInfo";
import Discounts from "./pages/Discounts";
import ErrorFallback from "./pages/ErrorFallback";
import Login from "./pages/Login";
import Logout from "./components/Logout";
import MemberType from "./pages/MemberType";
import NotAvailable from "./pages/NotAvailable";
import Opportunities from "./pages/Opportunities";
import Review from "./pages/Review";
import SignIn from "./components/SignIn";
import SignOut from "./pages/SignOut";
import Start from "./pages/Start";
import ThankYou from "./pages/ThankYou";
import Pac from "./pages/Pac";
import AutoRenew from "./pages/AutoRenew";
import RecentOrder from "./pages/RecentOrder";

function App() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/start" />
        </Route>

        <Route path="/account" component={Account} />
        <Route path="/auto-renew" component={AutoRenew} />
        <Route path="/contact-info" component={ContactInfo} />
        <Route path="/discounts" component={Discounts} />
        <Route path="/error" component={ErrorFallback} />

        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/member-type" component={MemberType} />
        <Route path="/not-available" component={NotAvailable} />
        <Route path="/opportunities" component={Opportunities} />

        <Route path="/pac" component={Pac} />
        <Route path="/recent" component={RecentOrder} />
        <Route path="/review" component={Review} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signout" component={SignOut} />

        <Route path="/start" component={Start} />
        <Route path="/thankyou" component={ThankYou} />
        <Route path="*" component={NotAvailable} />
      </Switch>
    </Sentry.ErrorBoundary>
  );
}

export default App;
