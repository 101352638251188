import React, { useState } from "react";
import NumberFormat from "react-number-format";

import { getCampaignByCode } from "../api/membershipApi";
import Spinner from "./ui/Spinner";

const Campaigns = ({
  handleCampaignData,
  campaign,
  personId,
  membershipProductId,
  isInternational,
}) => {
  const newCampaign = {
    id: 0,
    code: "",
    discountAmount: 0,
    isValid: false,
    message: "",
  };
  const [isSearching, setIsSearching] = useState(false);

  function _setCampaignCode(e) {
    const value = e.target.value;
    let newCampaign = campaign;
    newCampaign.code = value;
    handleCampaignData(newCampaign);
  }

  function _validateCampaignCode() {
    if (campaign.code === "") {
      return false;
    }

    setIsSearching(true);
    let updatedCampaign = campaign;

    if (!updatedCampaign.isValid) {
      const campaignRequest = {
        code: campaign.code,
        personId: parseInt(personId, 10),
        membershipProductId: parseInt(membershipProductId, 10),
      };

      updatedCampaign.isValid = false;
      updatedCampaign.message = "The code is not valid";

      getCampaignByCode(campaignRequest)
        .then((r) => {
          if (r.success) {
            if (r.data.id > 0) {
              updatedCampaign.id = r.data.id;
              updatedCampaign.discountAmount = r.data.discountAmount;
              updatedCampaign.isValid = true;
              updatedCampaign.message =
                "the code has successfully been applied";
            }
          }
          handleCampaignData(updatedCampaign);
        })
        .catch((error) => {});
    } else {
      updatedCampaign = newCampaign;
      handleCampaignData(updatedCampaign);
    }
    setIsSearching(false);
  }

  return (
    <>
      {isSearching ? (
        <Spinner />
      ) : (
        <>
          <tr class="review-form">
            <td colspan="2">
              <label for="discount">Enter a Membership Discount Code</label>
              <div class="grid-x grid-margin-x align-middle">
                <div class="cell medium-8">
                  <input
                    type="text"
                    id="discount"
                    disabled={campaign.isValid}
                    value={campaign.code}
                    onChange={_setCampaignCode}
                  />
                </div>
                <div class="cell medium-4 text-center medium-text-right">
                  <button
                    class="button "
                    type="button"
                    onClick={_validateCampaignCode}
                  >
                    {!campaign.isValid ? (
                      <span>Apply</span>
                    ) : (
                      <span>Remove</span>
                    )}
                  </button>
                </div>
              </div>
            </td>
          </tr>
          {campaign.isValid && (
            <tr>
              <th>
                <span class="iconSmall icon-discount"></span>
                Applied Discount Code
              </th>
              <td>
                <strong>
                  -{" "}
                  <NumberFormat
                    value={campaign.discountAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />{" "}
                  {isInternational && <span>USD</span>}
                </strong>
              </td>
            </tr>
          )}
          {!campaign.isValid && campaign.message !== "" && (
            <tr>
              <th>
                <span class="iconSmall icon-discount"></span>
                {campaign.message}
              </th>
              <td></td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default Campaigns;
