import React, { useState } from "react";
import { useHistory } from "react-router";
import * as Sentry from "@sentry/react";

import { useMembership } from "../context/membershipContext";
import { duesText, intlTransactionText } from "../data/config";
import { AvailableDiscounts } from "../data/data";
import { submitOrder } from "../api/membershipApi";
import { pacDonationProductId, paymentType } from "../data/config";

import Spinner from "./ui/Spinner";

const Payment = () => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const [ccv, setCcv] = useState("");
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [orderError, setOrderError] = useState(false);

  //Page Functions
  function _checkNumeric(number) {
    let val = false;
    if (typeof number != "string") return false;
    if (!isNaN(number) && !isNaN(parseFloat(number))) {
      val = true;
    }
    return val;
  }

  function _getCreditCardYears() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let yrs = [];
    for (var i = currentYear; i < currentYear + 10; i++) {
      yrs.push(i);
    }
    return yrs;
  }

  function _getPaymentType() {
    if (creditCardNumber.startsWith("4") && creditCardNumber.length > 12) {
      return paymentType.VISA;
    }

    if (creditCardNumber.startsWith("5") && creditCardNumber.length === 16) {
      return paymentType.MASTERCARD;
    }

    if (
      (creditCardNumber.startsWith("34") ||
        creditCardNumber.startsWith("37")) &&
      creditCardNumber.length === 15
    ) {
      return paymentType.AMEX;
    }

    if (creditCardNumber.startsWith("6") && creditCardNumber.length === 16) {
      return paymentType.DISCOVER;
    }

    return paymentType.INVALID;
  }

  function _luhnCheck() {
    let numberArray = (creditCardNumber + "")
      .split("")
      .reverse()
      .map((n) => parseInt(n));
    let lastDigit = numberArray.shift();
    let sum = numberArray.reduce(
      (acc, val, i) =>
        i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
      0
    );
    sum += lastDigit;
    return sum % 10 === 0;
  }

  function _removeCreditCardError(name) {
    let errors = errorList;
    let i = errors.indexOf(name);
    if (i > -1) {
      errors.splice(i, 1);
    }

    if (name === "expirationMonth" || name === "expirationYear") {
      i = errors.indexOf("expirationDate");
      if (i > -1) {
        errors.splice(i, 1);
      }
    }
    setErrorList(errors);
  }

  function _setCreditCardData(e) {
    const name = e.target.name;
    const value = e.target.value;

    _removeCreditCardError(name);

    switch (name) {
      case "creditCardNumber":
        const ccnumber = value.replace(/\D/g, "");
        setCreditCardNumber(ccnumber);
        break;

      case "ccv":
        const ccvnumber = value.replace(/\D/g, "");
        setCcv(ccvnumber);
        break;

      case "expirationMonth":
        setExpirationMonth(value);
        break;

      case "expirationYear":
        setExpirationYear(value);
        break;

      default:
        break;
    }
  }

  function _setErrorClass(fieldName, isLabel) {
    if (errorList.length > 0) {
      if (errorList.includes(fieldName)) {
        return isLabel ? "error-class-label" : "error-class";
      }
      return "";
    }
  }

  function _submitOrder(e) {
    if (e) {
      e.preventDefault();
    }

    const formIsValid = _validateCreditCard();
    setOrderError(false);
    if (membership.autoRenew === "true" && !membership.autoRenewConfirm) {
      let errors = ["autoRenew"];
      dispatch({ type: "updateErrorList", errorList: errors });
    }

    if (formIsValid && membership.errorList.length === 0) {
      setIsSearching(true);

      const paymentInformation = {
        creditCardNumber: creditCardNumber,
        ccv: ccv,
        payTypeId: _getPaymentType(),
        expirationDate:
          expirationYear + "-" + expirationMonth + "-01T00:00:00.000z",
      };

      let order = {
        shipToId: membership.personBasic.id,
        shipToAddrLine1: membership.address.addressLine1,
        shipToCity: membership.address.city,
        shipToState: membership.address.stateProvince,
        shipToZipCode: membership.address.postalCode,
        shipToCountry: membership.address.country,
        shipToCountryCodeID: membership.address.countryID,
        paymentInformation: paymentInformation,
        campaign: {
          id: 0,
        },
      };

      var baseMembershipPrice = membership.memberType.price;
      var discount = 0;

      if (membership.campaign.isValid) {
        discount =
          (membership.campaign.discountAmount / membership.memberType.price) *
          100;
        order.campaign.id = membership.campaign.id;
      }

      let orderLines = [];
      const membershipOrderLine = {
        autoRenew: membership.autoRenew === "true",
        discount: parseFloat(discount.toFixed(3)),
        isMembershipOrderLine: true,
        quantity: 1,
        price: baseMembershipPrice,
        productId: membership.memberType.id,
        userPricingOverride: true,
      };
      orderLines.push(membershipOrderLine);

      if (membership.autoRenew === "true") {
        const autoRenewDiscount = {
          autoRenew: true,
          productId: AvailableDiscounts["AutoRenew"].id,
          quantity: 1,
          userPricingOverride: false,
        };
        orderLines.push(autoRenewDiscount);
      }

      if (
        membership.veteranStatus === "active" ||
        membership.veteranStatus === "veteran"
      ) {
        const autoRenewDiscount = {
          autoRenew: membership.autoRenew === "true",
          productId: AvailableDiscounts["Military"].id,
          quantity: 1,
          userPricingOverride: false,
        };
        orderLines.push(autoRenewDiscount);
      }

      for (var i = 0; i < membership.selectedCommunities.length; i++) {
        let communityId = parseInt(membership.selectedCommunities[i], 10);
        let communityOrderLine = {};
        communityOrderLine.autoRenew = membership.autoRenew === "true";
        communityOrderLine.productId = communityId;
        communityOrderLine.quantity = 1;
        communityOrderLine.userPricingOverride = false;
        orderLines.push(communityOrderLine);
      }

      if (membership.personBasic.isFellow && membership.fellowsProduct.id > 0) {
        const fellowsOrderLine = {
          autoRenew: false,
          productId: membership.fellowsProduct.id,
          quantity: 1,
          userPricingOverride: false,
        };
        orderLines.push(fellowsOrderLine);
      }

      if (
        parseFloat(membership.pacDonation.amount) > 0 &&
        membership.personBasic.isMember
      ) {
        const pacDonationOrderLine = {
          autoRenew: membership.autoRenew === "true",
          comments: membership.pacDonation.recognize
            ? "Recognize this contribution"
            : "",
          isPacDonationOrderLine: true,
          productId: pacDonationProductId,
          quantity: 1,
          price: parseFloat(membership.pacDonation.amount),
          userPricingOverride: true,
        };
        orderLines.push(pacDonationOrderLine);
      }

      order.orderLines = orderLines;

      submitOrder(order)
        .then((response) => {
          let orderStatus = "failed";
          let orderId = 0;

          if (response.success) {
            if (response.data.orderId > 0) {
              orderId = response.data.orderId;
              orderStatus = "complete";
            }
          } else {
            if (response.data.orderId > 0) {
              orderStatus = "duplicate";
            }
          }

          dispatch({
            type: "updateOrderCompleteData",
            orderStatus: orderStatus,
            orderId: orderId,
            loginUrl: response.data.loginUrl,
          });

          if (orderStatus === "complete") {
            history.push("/thankyou");
          } else if (orderStatus === "duplicate") {
            history.push("/recent");
          } else {
            Sentry.captureMessage("Payment - order was not successful");
            setIsSearching(false);
            setOrderError(true);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          setIsSearching(false);
          setOrderError(true);
        });
    }
  }

  function _validateCreditCard() {
    let errors = [];
    //Check that nothing super weird has been entered
    if (typeof creditCardNumber != "string") return false;
    //Check that it is numeric
    if (!_checkNumeric(creditCardNumber)) {
      errors.push("creditCardNumber");
    }

    //Check that it is an accepted cc type
    if (_getPaymentType() === paymentType.INVALID) {
      if (!errors.includes("creditCardNumber")) {
        errors.push("creditCardNumber");
      }
    }
    //Luhn check on the number
    if (!_luhnCheck()) {
      if (!errors.includes("creditCardNumber")) {
        errors.push("creditCardNumber");
      }
    }
    //CCV check
    if (!_checkNumeric(ccv) || ccv.length < 3) {
      errors.push("ccv");
    }
    //Expiration Month check
    if (expirationMonth === "") {
      errors.push("expirationMonth");
    }
    //Expiration Year check
    if (expirationYear === "") {
      errors.push("expirationYear");
    }

    const currentDate = new Date();
    const ccDate = new Date(expirationYear, expirationMonth, 1, 0, 0);
    if (currentDate > ccDate) {
      errors.push("expirationDate");
    }

    setErrorList(errors);

    if (errors.length === 0) {
      return true;
    }

    return false;
  }

  if (isSearching) return <Spinner />;

  return (
    <>
      <form
        onSubmit={(e) => {
          _submitOrder(e);
        }}
      >
        <div class="featured">
          <h3 class="featured-heading">Payment Information</h3>
          <div class="grid-x grid-margin-x align-bottom">
            <div class="cell medium-5 medium-order-2">
              <p class="iconCard-list">
                <img
                  src="images/icons/visa.svg"
                  alt="Visa"
                  class="iconCard"
                  style={{ maxWidth: "48px" }}
                />
                <img
                  src="images/icons/mastercard.svg"
                  alt="MasterCard"
                  class="iconCard"
                  style={{ maxWidth: "48px" }}
                />
                <img
                  src="images/icons/american-express.svg"
                  alt="American Express"
                  class="iconCard"
                  style={{ maxWidth: "48px" }}
                />
                <img
                  src="images/icons/discover-card.svg"
                  alt="Discover"
                  class="iconCard"
                  style={{ maxWidth: "48px" }}
                />
              </p>
            </div>

            <div class="cell medium-7 medium-order-1">
              <div class="input-wrapper">
                <label
                  htmlFor="creditCardNumber"
                  className={_setErrorClass("creditCardNumber", true)}
                >
                  Credit Card Number
                </label>
                <input
                  type="text"
                  maxlength="16"
                  name="creditCardNumber"
                  id="creditCardNumber"
                  autoComplete="cc-number"
                  className={_setErrorClass("creditCardNumber", false)}
                  onChange={_setCreditCardData}
                  value={creditCardNumber}
                />
              </div>
            </div>
          </div>
          {errorList.includes("creditCardNumber") && (
            <div class="grid-x grid-margin-x align-bottom">
              <div class="cell medium-12" style={{ marginBottom: "20px" }}>
                <div class="warning-message">
                  * Please enter a valid Credit Card Number
                </div>
              </div>
            </div>
          )}
          <div class="grid-x grid-margin-x">
            <div class="cell medium-7">
              <div class="grid-x grid-margin-x">
                <div class="cell small-6">
                  <div class="input-wrapper">
                    <label
                      for="expirationMonth"
                      className={_setErrorClass("expirationMonth", true)}
                    >
                      Expiration Month
                    </label>
                    <select
                      name="expirationMonth"
                      id="expirationMonth"
                      placeholder="MM"
                      autoComplete="cc-exp-month"
                      className={_setErrorClass("expirationMonth", false)}
                      value={expirationMonth}
                      onChange={_setCreditCardData}
                    >
                      <option value=""></option>
                      <option value="01">Jan</option>
                      <option value="02">Feb</option>
                      <option value="03">Mar</option>
                      <option value="04">Apr</option>
                      <option value="05">May</option>
                      <option value="06">Jun</option>
                      <option value="07">Jul</option>
                      <option value="08">Aug</option>
                      <option value="09">Sep</option>
                      <option value="10">Oct</option>
                      <option value="11">Nov</option>
                      <option value="12">Dec</option>
                    </select>
                  </div>
                  {errorList.includes("expirationMonth") && (
                    <div class="warning-message">*Exp Month Required</div>
                  )}
                </div>
                <div class="cell small-6">
                  <div class="input-wrapper">
                    <label
                      for="expirationMonth"
                      className={_setErrorClass("expirationYear", true)}
                    >
                      Expiration Year
                    </label>
                    <select
                      name="expirationYear"
                      id="expirationYear"
                      placeholder="YYYY"
                      autoComplete="cc-exp-year"
                      className={_setErrorClass("expirationYear", false)}
                      value={expirationYear}
                      onChange={_setCreditCardData}
                    >
                      <option value=""></option>
                      {_getCreditCardYears().map((yr) => {
                        return <option value={yr}>{yr}</option>;
                      })}
                    </select>
                  </div>
                  {errorList.includes("expirationYear") && (
                    <div class="warning-message">*Exp Year Required</div>
                  )}
                </div>
              </div>
              {errorList.includes("expirationDate") &&
                !errorList.includes("expirationMonth") &&
                !errorList.includes("expirationYear") && (
                  <div class="grid-x grid-margin-x align-bottom">
                    <div
                      class="cell medium-12"
                      style={{ marginBottom: "20px" }}
                    >
                      <div class="warning-message">
                        * Exp Mon + Exp Yr should be this Mon + Yr or later
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div class="cell medium-4 small-6">
              <div class="input-wrapper">
                <label for="ccv" className={_setErrorClass("ccv", true)}>
                  CCV{" "}
                  <button
                    type="button"
                    class="tooltip-icon"
                    tabindex="-1"
                    title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore."
                  >
                    <span class="show-for-sr">Trigger tooltip</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                    >
                      <defs></defs>
                      <circle
                        className="a"
                        cx="8.5"
                        cy="8.5"
                        r="8.5"
                        style={{ fill: "#3072d7" }}
                      />
                      <text
                        className="b"
                        transform="translate(6 13)"
                        style={{
                          fill: "#fff",
                          fontSize: "12px",
                          fontFamily: "OpenSans-Bold, Open Sans",
                          fontWeight: "700",
                        }}
                      >
                        <tspan x="0" y="0">
                          ?
                        </tspan>
                      </text>
                    </svg>
                  </button>
                </label>
                <input
                  type="text"
                  name="ccv"
                  id="ccv"
                  autoComplete="cc-csc"
                  className={_setErrorClass("ccv", false)}
                  placeholder="234"
                  maxlength="4"
                  value={ccv}
                  onChange={_setCreditCardData}
                />
              </div>
              {errorList.includes("ccv") && (
                <div class="warning-message">*Enter a 3 or 4 digit number</div>
              )}
            </div>
          </div>
        </div>
        {membership.orderIsValid && (
          <h3 class="text-center" style={{ color: "red", fontWeight: "bold" }}>
            <p></p>
          </h3>
        )}

        <div class="box-controls">
          <p>{duesText}</p>
          <>
            {membership.memberType.isInternational && (
              <p>{intlTransactionText}</p>
            )}
          </>
          <p>
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              By clicking the Submit button below, you agree to AANP's
              <br />
              <a
                href="https://www.aanp.org/aanp-terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              (opens in a new window) and{" "}
              <a
                href="https://www.aanp.org/aanp-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              (opens in a new window) .
            </div>
          </p>
          {membership.errorList.length > 0 && (
            <>
              <div
                className="error-class-label text-center"
                style={{ fontWeight: "bold" }}
              >
                *Please ensure that all required fields have been completed.
              </div>
              <br />
            </>
          )}
          {orderError && (
            <>
              <div
                className="error-class-label text-center"
                style={{ fontWeight: "bold" }}
              >
                *There was an error processing your order. We apologize for the
                inconvenience, please try again later.
              </div>
              <br />
            </>
          )}

          <button type="submit" class="button secondary">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Payment;
