import { React } from "react";
import Header from "../components/ui/Header";
import { welcomeText } from "../data/config";
//import { useMembership } from "../context/membershipContext";

const ErrorFallback = ({ error }) => {
  //const { membership } = useMembership();
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const marginTop = {
    marginTop: isMobile ? "20px" : "",
  };
  return (
    <>
      <Header isMember={false} />
      <div style={{ position: "relative" }}>
        <div className="grid-container" id={isMobile ? "join-banner" : ""}>
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div style={{ paddingBottom: "4rem" }}>{welcomeText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container join-content">
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box">
              <div className="page is-active is-loaded" style={marginTop}>
                <div className="box-header">
                  <legend>There has been an error</legend>
                </div>
                <div className="box-content">
                  <fieldset className="box-section">
                    <h3 className="box-section-heading">
                      The AANP Membership App is currently unavailable{" "}
                    </h3>
                    <p className="input-wrapper">
                      We apologize for the inconvenience, but there has been an
                      error processing your information. Please know that you
                      are very important to us; to resolve this matter, please
                      contact our membership department at (512) 505-0242 or by
                      email at{" "}
                      <a href="mailto:membership@aanp.org">
                        membership@aanp.org
                      </a>
                      .
                    </p>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorFallback;
