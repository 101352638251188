import React from "react";

const DateOfBirthModal = ({ closeModal }) => {
  return (
    <div class="modal-body">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-11">
          <h3>Why AANP Needs Date of Birth</h3>
        </div>

        <div className="cell small-1">
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
      </div>
      <p>
        Obtaining this information would increase our understanding of how to
        improve the member experience and promote diverse NP voices on a global
        scale.
      </p>
    </div>
  );
};

export default DateOfBirthModal;
