import React, { useEffect, useState } from "react";
import { useMembership } from "../context/membershipContext";
import * as _initValues from "../context/initialValues";
//import { contactUrl, memberBenefitsUrl, pacUrl } from "../data/config";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const ThankYou = () => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const [accountUrl, setAccountUrl] = useState();
  const [isInternational, setIsInternational] = useState();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.removeItem("aanp_countries");

    if (membership.personBasic.id <= 0 || membership.orderId <= 0) {
      history.push("/error");
    }

    //create a regex for emails

    if (membership.orderId > 0) {
      Cookies.set("refreshWwwUser", true, {
        path: "/",
        domain: ".aanp.org",
        httpOnly: false,
        sameSite: "lax",
        expires: 10,
      });
    }

    setAccountUrl(membership.loginUrl);
    setIsInternational(membership.memberType.isInternational);
    // const purchase = membership.personBasic.isMember
    //   ? "Renew"
    //   : membership.personBasic.isInSystem
    //   ? "Return"
    //   : "Join";
    // const script = document.createElement("script");
    // script.src =
    //   "dataLayer.push({'event':'virtualPageView', 'virtualPageURL':'/virtual/Membership/" +
    //   purchase +
    //   "/ProfileUpdates','virtualPageTitle' : 'Update Profile'});";
    // script.async = true;
    // document.body.appendChild(script);

    const updatedMembership = _initValues.membership;
    dispatch({ type: "updateMembership", updatedMembership });

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return (
    <div class="grid-container join-content">
      <div class="grid-x grid-margin-x align-center">
        <div class="cell large-10">
          <div class="box">
            <div
              class="box-content box-content--secondary"
              style={{ marginTop: "2rem" }}
            >
              <div class="grid-x grid-margin-x align-center">
                <div
                  class="cell large-10"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <h2 class="headingIcon">
                    <span class="headingIcon-icon">
                      <span class="iconCircle-icon icon-checkmark"></span>
                    </span>
                    Your membership was successfully processed.
                  </h2>
                  <p>
                    A receipt of your payment has been sent to the email you
                    provided for your AANP account. You can view a copy of your
                    receipt in the “Orders/Receipts” section on your{" "}
                    <a
                      href="https://account.aanp.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AANP account
                    </a>
                    . While viewing your account page, you can also:
                  </p>
                  <ul class="listUnstyled">
                    <li>
                      <a
                        href="https://account.aanp.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Print your AANP Membership Card
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://account.aanp.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Print your AANP Membership Certificate
                      </a>
                    </li>
                    {!isInternational && (
                      <li>
                        <a
                          href="https://www.aanp.org/advocacy/federal/aanp-pac-members"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn about and support the AANP-PAC
                        </a>{" "}
                      </li>
                    )}
                  </ul>
                  <p>
                    <a
                      className="button"
                      type="button"
                      href="https://account.aanp.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Started
                    </a>
                  </p>
                  <p>
                    Welcome to AANP! During the next few weeks, you’ll begin to
                    receive emails that introduce your membership benefits.{" "}
                  </p>

                  <p>
                    Want to get a head start? Visit the{" "}
                    <a
                      href="https://www.aanp.org/membership/member-benefits/membership-impact-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AANP Membership IMPACT Center
                    </a>{" "}
                    to learn more about the valuable benefits that AANP members
                    enjoy.
                  </p>
                  <p>
                    If you have any questions, the Member Care Center is
                    available to you by email{" "}
                    <a href="mailto:membership@aanp.org">membership@aanp.org</a>{" "}
                    or by calling (512) 442-0242 (10 a.m. to 6 p.m. Eastern
                    Time). Please visit and bookmark the{" "}
                    <a
                      href="https://www.aanp.org/contact"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Us
                    </a>{" "}
                    webpage so you know how to reach us. Thank you for joining
                    AANP!
                  </p>
                  <div class="flex-video widescreen">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/ZyGOzw1kLw0"
                      title="We Choose NPs"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  {isInternational && (
                    <div>
                      Certain aspects of AANP services, member benefits,
                      information, content, materials, and products may be
                      unavailable to international members.{" "}
                      <a
                        href="https://www.aanp.org/membership/international-member-benefits"
                        target="_blank"
                        rel="noreferrer"
                      >
                        See additional information
                      </a>
                      . For questions,{" "}
                      <a
                        href="https://www.aanp.org/contact"
                        target="_blank"
                        rel="noreferrer"
                      >
                        submit your inquiry
                      </a>
                      .
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
