import React from "react";
import Header from "../components/ui/Header";
import { welcomeText } from "../data/config";

const AutoRenew = () => {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const marginTop = {
    marginTop: isMobile ? "20px" : "",
  };

  return (
    <>
      <Header isMember={false} />
      <div style={{ position: "relative" }}>
        <div className="grid-container" id={isMobile ? "join-banner" : ""}>
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div style={{ paddingBottom: "4rem" }}>{welcomeText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container join-content">
        <div className="grid-x grid-margin-x align-center" style={marginTop}>
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <div className="box-progress">
                  <div className="grid-x grid-margin-x align-middle">
                    <div className="cell small-2">
                      <button
                        className=""
                        style={{
                          fontSize: "2rem",
                          margin: "20px 0",
                          listStyle: "none",
                          textAlign: "center",
                          cursor: "pointer",
                          transform: "rotate(90deg)",
                          color: "#ccc",
                        }}
                        disabled
                      >
                        <span className="pager-icon icon-caret"></span>
                        <span className="show-for-sr">Previous</span>
                      </button>
                    </div>
                    <div className="cell small-8">
                      You're signing up for: <strong>AANP membership</strong>
                      <span className="progress">
                        <span
                          className="progress-bar"
                          style={{ width: "20%" }}
                        ></span>
                      </span>
                    </div>
                    <div className="cell small-2">
                      <button
                        className=""
                        style={{
                          fontSize: "2rem",
                          margin: "20px 0",
                          listStyle: "none",
                          textAlign: "center",
                          cursor: "pointer",
                          transform: "rotate(-90deg)",
                          color: "#ccc",
                        }}
                        disabled
                      >
                        <span className="pager-icon icon-caret"></span>
                        <span className="show-for-sr">Next</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="box-content" style={{ height: "20em" }}>
                  <div className="text-center">
                    <div className="box-section">
                      <h3 className="box-section-heading">
                        Your Membership is Set to Auto-Renew
                      </h3>
                      <p className="input-wrapper">
                        Auto renew is a convenient way to keep your membership
                        current and save 5% each year on dues. While opted-in to
                        auto renew, AANP will automatically charge the credit
                        card on file the applicable membership dues each year.
                        To change your auto-renew options, please contact the
                        Member Care Center at (512) 505-0242 during the hours of
                        10 am-6pm ET or send an email to{" "}
                        <a href="mailto:membership@aanp.org">
                          membership@aanp.org
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AutoRenew;
