import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import NumberFormat from "react-number-format";

import { useMembership } from "../context/membershipContext";
import { getAllCommunities } from "../api/membershipApi";
import { modalStyles } from "../styles/modalStyles";
import { isNavigationValid } from "../utils/functions";

import NetworkForResearchModal from "../components/modals/NetworkForResearchModal";
import Spinner from "../components/ui/Spinner";
import TopNav from "../components/ui/TopNav";
import Header from "../components/ui/Header";

const Opportunities = (prop) => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();
  const [addCommunities, setAddCommunities] = useState([]);
  const [communities, setCommunities] = useState(membership.communities);
  const [errorList, setErrorList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [nfrModalIsOpen, setNfrModalIsOpen] = useState(false);
  const [removeCommunities, setRemoveCommunities] = useState([]);
  const [showSpgs, setShowSpgs] = useState(false);

  useEffect(() => {
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    if (membership.memberType.isInternational) {
      let updatedMembership = membership;
      updatedMembership.opportunities.npFinder = "false";
      dispatch({ type: "updateMembership", updatedMembership });
    }

    if (!communities) {
      setIsSearching(true);
      async function getCommunities() {
        getAllCommunities().then((response) => {
          if (response.data.success) {
            const communities = response.data.list;
            setCommunities(communities);

            let updatedMembership = membership;
            updatedMembership.communities = communities;
            dispatch({ type: "updateMembership", updatedMembership });
            sessionStorage.setItem(
              "session_membership",
              JSON.stringify(updatedMembership)
            );
            setIsSearching(false);
          }
        });
      }
      getCommunities();
    }
  }, []);

  //Stock function to set app storage values based on user input
  function _addToAccount() {
    let selectedCommunities = membership.selectedCommunities;
    const addedCommunities = selectedCommunities.concat(addCommunities);
    const updatedCommunities = addedCommunities.filter(
      (c) => !removeCommunities.includes(c)
    );

    dispatch({
      type: "updateSelectedCommunities",
      selectedCommunities: updatedCommunities,
    });
    sessionStorage.setItem(
      "session_selectedCommunities",
      JSON.stringify(updatedCommunities)
    );
    setAddCommunities([]);
    setRemoveCommunities([]);
    setShowSpgs(false);

    document
      .getElementById("member-header")
      .scrollIntoView({ behavior: "smooth" });
  }

  function _getCommunityName(id) {
    let communityName = "";
    if (communities.length > 0) {
      const community = communities.find((c) => c.id === parseInt(id, 10));
      if (community) {
        communityName = community.name;
      }
    }
    return communityName;
  }

  //Calculate the membership discount based on selected member type and selected discounts
  function _getDiscountTotal() {
    const baseMembershipPrice = membership.memberTypes.find(
      (mt) => mt.id === parseInt(membership.memberType.id, 10)
    ).price;
    let discountTotal = 0;
    let autoRenewDiscount = 0;

    if (membership.autoRenew && membership.autoRenewConfirm) {
      autoRenewDiscount = baseMembershipPrice * 0.05;
      discountTotal += autoRenewDiscount;
    }

    if (
      membership.veteranStatus === "active" ||
      membership.veteranStatus === "veteran"
    ) {
      discountTotal += 10;
    }

    return discountTotal;
  }

  //Validate to make sure that all values have been set before user can nav forward
  function _isPageComplete() {
    return (
      membership.opportunities.networkForResearch !== "" &&
      membership.opportunities.npFinder !== ""
    );
  }

  function _removeErrorClass(name) {
    let newErrors = errorList.filter((e) => e !== name);
    if (name === "email") {
      newErrors = newErrors.filter((e) => e !== "emailInvalid");
    }
    setErrorList(newErrors);
  }

  function _setOpportunityData(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedMembership = membership;
    _removeErrorClass(name);

    switch (name) {
      case "networkForResearch":
        updatedMembership.opportunities.networkForResearch = value;
        break;

      case "npFinder":
        updatedMembership.opportunities.npFinder = value;
        break;

      default:
        break;
    }
    dispatch({ type: "updateMembership", updatedMembership });
  }

  //Stock function to set app storage values based on user input
  function _setSelectedCommunities(e) {
    let changes = [];

    //Determine the value of the checked community; and whether it was selected or unselected
    const value = parseInt(e.target.value, 10);
    const isChecked = e.target.checked;
    //A user is toggling back and forth on a community, check to see if that community is already part of
    //the communities that had been selected
    const alreadySelected =
      addCommunities.includes(value) ||
      membership.selectedCommunities.includes(value);

    //If a community has been checked, and is not already in the list of chosen communities, add it
    //In the else statement, if it has been unchecked and already exists in the listof chosen communities, remove it
    if (isChecked) {
      if (!alreadySelected) {
        changes = addCommunities;
        changes.push(value);
        setAddCommunities(changes);

        if (removeCommunities.includes(value)) {
          changes = removeCommunities;
          changes.pop(value);
          setRemoveCommunities(changes);
        }
      }
    } else {
      if (alreadySelected) {
        changes = removeCommunities;
        changes.push(value);
        setRemoveCommunities(changes);

        if (addCommunities.includes(value)) {
          changes = addCommunities;
          changes.pop(value);
          setAddCommunities(changes);
        }
      }
    }
  }

  function _submitOpportunities() {
    let errors = [];
    if (membership.opportunities.networkForResearch === "")
      errors.push("networkForResearch");
    if (membership.opportunities.npFinder === "") errors.push("npFinder");
    setErrorList(errors);

    if (errors.length === 0) {
      sessionStorage.setItem("session_membership", JSON.stringify(membership));
      history.push("/account", { from: pathname, propState: "" });
    }
  }

  //Navigate either forward to account, or backward to discounts
  //Save opportunity info to session storage before navigating
  function _navigateToStep(step) {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    setErrorList([]);
    let errors = [];

    if (step === "account") {
      if (membership.opportunities.networkForResearch === "")
        errors.push("networkForResearch");
      if (membership.opportunities.npFinder === "") errors.push("npFinder");
      setErrorList(errors);
    } else if (step === "discounts") {
      if (membership.memberType.isInternational) {
        return history.push("/member-type", { from: pathname, propState: "" });
      }
    }

    if (errors.length === 0) {
      history.push(step, { from: pathname, propState: "" });
    }
  }

  //Stock functions to open and close the modal (Tell me more modal about auto-renew)
  function _closeCommunityList() {
    setAddCommunities([]);
    setRemoveCommunities([]);
    setShowSpgs(false);
    document
      .getElementById("member-header")
      .scrollIntoView({ behavior: "smooth" });
  }

  function _openCommunityList() {
    setShowSpgs(true);
  }

  function _openModal(name) {
    setNfrModalIsOpen(true);
  }

  function _closeModal() {
    setNfrModalIsOpen(false);
  }

  return (
    <>
      <Header isMember={membership.personBasic.isMember} />
      <div
        className="grid-container join-content"
        style={{ paddingTop: "10px" }}
      >
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <TopNav
                  currentStep="opportunities"
                  backwardStepDisabled={false}
                  forwardStepDisabled={!_isPageComplete()}
                  handleNavigate={_navigateToStep}
                />
              </div>
              <div className="box-content">
                <div className="text-center box-content-intro">
                  {_getDiscountTotal() > 0 && (
                    <h2 className="box-content-heading">
                      You qualified for a{" "}
                      <strong>
                        <NumberFormat
                          value={_getDiscountTotal()}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </strong>{" "}
                      {membership.memberType.isInternational && (
                        <span> USD</span>
                      )}
                      discount on your AANP membership!
                    </h2>
                  )}
                  <h2 className="box-content-heading" id="step-header">
                    Additional Opportunities
                  </h2>
                  <p>
                    <span className="helper">
                      * Indicates a required field.
                    </span>
                  </p>
                </div>
                <form>
                  {isSearching ? (
                    <Spinner />
                  ) : (
                    <div className="box-section">
                      <h3 className="box-section-heading">
                        Join an AANP Community
                      </h3>
                      <p className="box-section-summary">
                        AANP offers a number of members-only communities
                        organized around therapeutic areas and professional
                        interests. Designed to support discussion, document
                        sharing, collaboration and networking, each community
                        maintains a dedicated online forum where you can
                        instantly connect with like-minded NP colleagues.
                      </p>
                      <div className="box-section-highlight">
                        {!showSpgs ? (
                          <>
                            {membership.selectedCommunities.length > 0 && (
                              <table className="clean">
                                <caption>A list of AANP Communities</caption>
                                <thead>
                                  <tr>
                                    <th>Added Communities:</th>
                                    <th>
                                      <span className="show-for-sr">
                                        Remove link
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {membership.selectedCommunities.map(
                                    (item) => {
                                      return (
                                        <tr>
                                          <td>{_getCommunityName(item)}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            )}
                            <button
                              onClick={() => _openCommunityList()}
                              className="remove-button"
                              style={{ cursor: "pointer" }}
                            >
                              {membership.selectedCommunities.length === 0 ? (
                                <span>+ Add a Community</span>
                              ) : (
                                <span>+ Change Community Selections</span>
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="grid-x grid-margin-x">
                              <div className="cell small-11 align-center">
                                <h3 className="text-left medium-text-center">
                                  Choose from the following communities
                                </h3>
                              </div>
                              {communities.length > 0 ? (
                                <>
                                  <fieldset class="radioFancy-wrapper">
                                    {" "}
                                    <div className="row radioFancy">
                                      {communities.map((c) => {
                                        return (
                                          <div
                                            className="cell"
                                            style={{ marginBottom: "8px" }}
                                          >
                                            <input
                                              key={c.id}
                                              className="radioFancy-input"
                                              type="checkbox"
                                              name={"spg_" + c.id}
                                              id={"spg_" + c.id}
                                              value={c.id}
                                              onChange={_setSelectedCommunities}
                                              defaultChecked={membership.selectedCommunities.includes(
                                                c.id
                                              )}
                                            />
                                            <label
                                              htmlFor={"spg_" + c.id}
                                              className="radioFancy-label"
                                            >
                                              {c.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </fieldset>
                                  <div className="row">
                                    <div
                                      className="col"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <div className="text-center">
                                        <button
                                          type="submit"
                                          className="button"
                                          onClick={_addToAccount}
                                        >
                                          Update
                                        </button>
                                      </div>
                                      <div
                                        className="col"
                                        style={{
                                          marginTop: "15px",
                                        }}
                                      >
                                        <div className="text-center">
                                          <button
                                            type="reset"
                                            className="communities-cancel"
                                            onClick={() =>
                                              _closeCommunityList()
                                            }
                                            style={{
                                              fontSize: "1.7rem",
                                              fontWeight: "600",
                                              textDecoration: "Underline",
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="text-center">
                                  <Spinner />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <span className="helper">
                        Note: there is an additional $20
                        {membership.memberType.isInternational && (
                          <span> USD</span>
                        )}{" "}
                        cost for joining each community.
                      </span>
                    </div>
                  )}

                  {!showSpgs && (
                    <>
                      <div className="box-section">
                        <h3 className="box-section-heading">
                          Network for Research: Support NP Research
                        </h3>
                        <p className="box-section-summary">
                          The AANP Network for Research (AANPNR) is an online
                          community for AANP members interested in discussing
                          and learning more about research related to nurse
                          practitioners (NPs).
                          <br />
                          <button
                            onClick={() => _openModal()}
                            className="modal-link"
                            type="button"
                            style={{ marginLeft: "5px" }}
                          >
                            Tell me more about AANPNR.
                          </button>
                        </p>

                        <fieldset>
                          <legend>
                            <span
                              className={
                                errorList.includes("networkForResearch")
                                  ? "error-class-label"
                                  : ""
                              }
                            >
                              Are you interested in joining AANPNR? *
                            </span>
                          </legend>

                          <div className="radioFancy">
                            <input
                              type="radio"
                              id="networkForResearch_yes"
                              className="radioFancy-input"
                              name="networkForResearch"
                              value="true"
                              onChange={_setOpportunityData}
                              checked={
                                membership.opportunities.networkForResearch ===
                                "true"
                              }
                              disabled={
                                membership.opportunities
                                  .currentNetworkForResearch &&
                                membership.personBasic.id > 0
                              }
                            />
                            <label
                              htmlFor="networkForResearch_yes"
                              className="radioFancy-label"
                            >
                              Yes
                            </label>
                          </div>

                          <div
                            className="radioFancy"
                            style={{
                              opacity:
                                membership.opportunities
                                  .currentNetworkForResearch &&
                                membership.personBasic.id > 0
                                  ? "0.4"
                                  : "1",
                            }}
                          >
                            <input
                              type="radio"
                              id="networkForResearch_no"
                              className="radioFancy-input"
                              name="networkForResearch"
                              value="false"
                              onChange={_setOpportunityData}
                              checked={
                                membership.opportunities.networkForResearch ===
                                "false"
                              }
                              disabled={
                                membership.opportunities
                                  .currentNetworkForResearch &&
                                membership.personBasic.id > 0
                              }
                            />

                            <label
                              htmlFor="networkForResearch_no"
                              className="radioFancy-label"
                            >
                              Not at this time
                            </label>
                          </div>

                          {errorList.includes("networkForResearch") && (
                            <div
                              style={{
                                fontSize: "1.3rem",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "1rem",
                              }}
                            >
                              Network for Research is Required
                            </div>
                          )}

                          {membership.opportunities.networkForResearch ===
                            "true" && membership.personBasic.id > 0 ? (
                            <span className="helper">
                              Thank you for utilizing this benefit!
                            </span>
                          ) : (
                            <span className="helper">
                              Note: this is a free, zero-cost benefit!
                            </span>
                          )}
                        </fieldset>
                      </div>
                      {!membership.memberType.isInternational && (
                        <div className="box-section">
                          <h3 className="box-section-heading">
                            Join NP Finder
                          </h3>
                          <p>
                            NP Finder is an online tool that helps patients
                            search for an NP to provide care. NP Finder is a
                            members-only benefit. Be sure to update “Practice
                            Sites” in your AANP MyAccount so your locations
                            display in NP Finder. Up to three practice sites can
                            be added. Would you like to join NP Finder and
                            continue to build your patient panel?
                          </p>
                          <fieldset>
                            <legend>
                              <span
                                className={
                                  errorList.includes("npFinder")
                                    ? "error-class-label"
                                    : ""
                                }
                              >
                                Would you like to join thousands of your fellow
                                AANP members on NP Finder? *
                              </span>
                            </legend>

                            <div className="radioFancy">
                              <input
                                type="radio"
                                id="npFinder_yes"
                                className="radioFancy-input"
                                name="npFinder"
                                value="true"
                                onChange={_setOpportunityData}
                                checked={
                                  membership.opportunities.npFinder === "true"
                                }
                                disabled={
                                  membership.opportunities.currentNpFinder &&
                                  membership.personBasic.id > 0
                                }
                              />
                              <label
                                htmlFor="npFinder_yes"
                                className="radioFancy-label"
                              >
                                Yes
                              </label>
                            </div>

                            <div
                              className="radioFancy"
                              style={{
                                opacity:
                                  membership.opportunities.currentNpFinder &&
                                  membership.personBasic.id > 0
                                    ? "0.4"
                                    : "1",
                              }}
                            >
                              <input
                                type="radio"
                                id="npFinder_no"
                                className="radioFancy-input"
                                name="npFinder"
                                value="false"
                                onChange={_setOpportunityData}
                                checked={
                                  membership.opportunities.npFinder === "false"
                                }
                                disabled={
                                  membership.opportunities.currentNpFinder &&
                                  membership.personBasic.id > 0
                                }
                              />
                              <label
                                htmlFor="npFinder_no"
                                className="radioFancy-label"
                              >
                                Not at this time
                              </label>
                            </div>

                            {errorList.includes("npFinder") && (
                              <div
                                style={{
                                  fontSize: "1.3rem",
                                  color: "red",
                                  fontWeight: "bold",
                                  marginBottom: "1rem",
                                }}
                              >
                                NP Finder is Required
                              </div>
                            )}

                            {membership.opportunities.npFinder === "true" &&
                            membership.personBasic.id > 0 ? (
                              <span className="helper">
                                Thank you for utilizing this benefit!
                              </span>
                            ) : (
                              <span className="helper">
                                Note: this is a free, zero cost benefit!
                              </span>
                            )}
                          </fieldset>
                        </div>
                      )}
                      {(errorList.includes("npFinder") ||
                        errorList.includes("networkForResearch")) && (
                        <div
                          className="error-class-label text-center"
                          style={{ fontWeight: "bold" }}
                        >
                          *Please ensure that all required fields have been
                          completed.
                        </div>
                      )}

                      <div className="box-controls">
                        <button
                          className="button"
                          type="button"
                          disabled={!_isPageComplete()}
                          onClick={() => _submitOpportunities()}
                        >
                          Continue
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={nfrModalIsOpen}
        onRequestClose={() => _closeModal}
        style={modalStyles}
        contentLabel="Network for Research"
      >
        <NetworkForResearchModal closeModal={_closeModal} />
      </Modal>
    </>
  );
};

export default Opportunities;
