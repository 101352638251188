export const getAddressErrors = (address, countries, states) => {
  const result = [];
  if (!address.addressType) result.push("addressType");
  if (!address.addressLine1) result.push("addressLine1");
  if (!address.city) result.push("city");
  if (!address.stateProvince && states.length > 0) result.push("stateProvince");
  if (!address.postalCode) result.push("postalCode");
  if (parseInt(address.countryID, 10) === 0) result.push("countryID");
  if (address.addressType === "work" && !address.companyName)
    result.push("companyName");
  return result;
};

export const getPasswordErrors = (password, passwordConfirm) => {
  const result = [];
  if (!passwordConfirm) result.push("passwordConfirm");
  if (!password) {
    result.push("password");
    return result;
  }

  if (password !== passwordConfirm) {
    result.push("password");
    result.push("passwordConfirm");
    return result;
  }

  if (!password.match(new RegExp("^(?=.*[A-Z].*)(?=.*[a-z])"))) {
    result.push("password");
    return result;
  }

  const numericRegex = /\d/;
  if (!numericRegex.test(password)) {
    result.push("password");
    return result;
  }

  return result;
};

export const getPersonBasicErrors = (personBasic) => {
  const result = [];
  if (!personBasic.firstName) result.push("firstName");
  if (!personBasic.lastName) result.push("lastName");
  if (!personBasic.email) result.push("email");

  var emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!emailPattern.test(personBasic.email)) {
    result.push("email");
    result.push("emailInvalid");
  }
  if (personBasic.email !== personBasic.emailConfirm)
    result.push("emailConfirm");
  return result;
};

export const getPhoneErrors = (phone) => {
  const result = [];
  if (!phone.phoneType) result.push("phoneType");
  if (!phone.phoneNumber) result.push("phoneNumber");
  if (phone.phoneNumber) {
    if (parseInt(phone.countryCode, 10) === 1) {
      if (phone.phoneNumber.length !== 11) result.push("phoneNumber");
      if (phone.areaCode.startsWith("1")) result.push("phoneNumber");
    } else {
      if (phone.phoneNumber.length < 8) result.push("phoneNumber");
    }
  }

  return result;
};

export const getNpiErrors = (npi, npiAttest) => {
  const result = [];
  if (
    ((npi === "" || npi === null) && !npiAttest) ||
    (npi !== "" && npi !== null && npiAttest)
  ) {
    result.push("npi");
  }
  if (
    npi !== "" &&
    npi !== null &&
    (isNaN(npi) || isNaN(parseFloat(npi))) &&
    result.length === 0
  ) {
    result.push("npi");
  }
  if (npi !== "" && npi !== null && npi.length !== 10 && result.length === 0) {
    result.push("npi");
  }
  return result;
};

export const getBirthYearErrors = (yr) => {
  const result = [];
  const currentYear = new Date().getFullYear();
  if (
    yr !== "" &&
    (isNaN(yr) ||
      isNaN(parseFloat(yr)) ||
      yr.length !== 4 ||
      yr < currentYear - 100 ||
      yr > currentYear - 15)
  ) {
    result.push("birthYear");
  }
  return result;
};

export const getBirthdateErrors = (mon, yr) => {
  const result = [];
  if ((mon !== "" && yr === "") || (mon === "" && yr !== "")) {
    result.push("birthdate");
  }

  if (mon !== "") {
    if (isNaN(mon)) result.push("birthdate");
    if (parseInt(mon, 10) > 12 || parseInt(mon, 10) < 1)
      result.push("birthdate");
  }

  const currentYear = new Date().getFullYear();
  const earliestYear = currentYear - 90;
  const latestYear = currentYear - 16;
  if (yr !== "") {
    if (isNaN(yr)) result.push("birthdate");
    if (parseInt(yr, 10) < earliestYear || parseInt(yr, 10) > latestYear)
      result.push("birthdate");
  }

  return result;
};
