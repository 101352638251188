import React, { useEffect } from "react";
import { useMembership } from "../context/membershipContext";
import { useHistory } from "react-router-dom";
import { signoutRedirect } from "../services/userService";
import Spinner from "./ui/Spinner";

const Logout = (props) => {
  const { dispatch } = useMembership();
  const history = useHistory();

  useEffect(() => {
    try {
      //Clear countries list out of local storage
      localStorage.removeItem("aanp_countries");
      //Remove join/renew date from session storage
      sessionStorage.removeItem("session_membership");
      dispatch({ type: "resetMembership" });

      //Log out of IdentityServer
      signoutRedirect();
    } catch (error) {
      history.push("/error");
    }
  }, []);

  return (
    <>
      <Spinner />
    </>
  );
};
export default Logout;
