import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMembership } from "../context/membershipContext";
import { UsStates, UsAndTerritoriesId } from "../data/data";
import { isNavigationValid } from "../utils/functions";
import TopNav from "../components/ui/TopNav";
import Header from "../components/ui/Header";

const Pac = (prop) => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    if (
      !membership.personBasic.isMember ||
      !UsAndTerritoriesId.some(
        (t) => t.countryID === parseInt(membership.address.countryID, 10)
      ) ||
      !UsStates.some((s) => s.stateAbb === membership.address.stateProvince)
    ) {
      history.push("/review", { from: pathname, propState: "" });
    }
  });

  function _removeErrorClass(name) {
    let errors = membership.errorList;
    let newErrors = errors.filter((e) => e !== name);
    dispatch({ type: "updateErrorList", errorList: newErrors });
  }

  function _setDonationData(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedMembership = membership;

    switch (name) {
      case "amount":
        updatedMembership.pacDonation.amount = value;
        if (value === "") updatedMembership.pacDonation.recognize = false;
        if (value !== "" && (isNaN(value) || isNaN(parseFloat(value)))) {
          const errors = membership.errorList;
          errors.push("amount");
          dispatch({ type: "updateErrorList", errorList: errors });
        } else if (!isNaN(value) && Math.sign(value) === -1) {
          const errors = membership.errorList;
          errors.push("amount");
          dispatch({ type: "updateErrorList", errorList: errors });
        } else {
          _removeErrorClass(name);
        }
        break;

      case "recognize":
        updatedMembership.pacDonation.recognize = e.target.checked;
        break;

      default:
        break;
    }
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  function _setErrorClass(fieldName, isLabel) {
    if (membership.errorList.length > 0) {
      if (membership.errorList.includes(fieldName)) {
        return isLabel ? "error-class-label" : "error-class";
      }
      return "";
    }
  }

  function _setPageComplete() {
    if (membership.errorList.includes("amount")) {
      return false;
    } else {
      return true;
    }
  }

  function _navigateToStep() {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    history.push("/review", { from: pathname, propState: "" });
  }

  return (
    <>
      <Header isMember={membership.personBasic.isMember} />
      <div
        className="grid-container join-content"
        style={{ paddingTop: "10px" }}
      >
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <TopNav
                  currentStep="pac"
                  backwardStepDisabled={true}
                  forwardStepDisabled={false}
                  handleNavigate={_navigateToStep}
                />
              </div>
              <div className="box-content">
                <div className="text-center box-content-intro">
                  <h2 className="box-content-heading">
                    The AANP Political Action Committee (AANP-PAC)
                  </h2>
                </div>

                <div className="box-section">
                  <h3 className="box-section-heading">About the AANP-PAC</h3>
                  <p className="box-section-summary">
                    The AANP-PAC provides an opportunity for members interested
                    in the welfare and future of NPs to contribute to the
                    support of candidates for national office who believe and
                    have demonstrated their beliefs in the purposes, principles
                    and mission of our organization.
                    <br />
                  </p>
                  <div className="grid-x grid-margin-x">
                    <div className="cell medium-6 small-6">
                      <fieldset>
                        <label
                          htmlFor="amount"
                          className={_setErrorClass("amount", true)}
                        >
                          AANP-PAC Contribution
                        </label>

                        <div className="input-wrapper">
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            className=""
                            value={membership.pacDonation.amount}
                            placeholder="$"
                            onChange={_setDonationData}
                          />
                        </div>
                      </fieldset>

                      {membership.errorList.includes("amount") && (
                        <div
                          className="error-class-label"
                          style={{
                            marginBottom: "15px",
                            fontSize: "1.3rem",
                            fontWeight: "600",
                          }}
                        >
                          Please enter a valid dollar amount
                        </div>
                      )}

                      <fieldset className="input-wrapper">
                        <input
                          type="checkbox"
                          id="pac_recognize"
                          name="recognize"
                          checked={
                            membership.pacDonation.recognize === true &&
                            membership.pacDonation.amount !== ""
                          }
                          value="1"
                          onChange={_setDonationData}
                          defaultChecked={false}
                          disabled={membership.pacDonation.amount === ""}
                        />
                        <label
                          htmlFor="pac_recognize"
                          style={{
                            color:
                              membership.pacDonation.amount === ""
                                ? "lightgray"
                                : "#000000",
                          }}
                        >
                          Yes, I would like this contribution to be recognized.
                        </label>
                      </fieldset>
                    </div>

                    <div className="cell medium-6 small-6">
                      <h4>AANP-PAC Contribution Levels</h4>
                      <div style={{ marginBottom: "6px", marginLeft: "10px" }}>
                        Platinum Club $1,000 and up
                      </div>
                      <div style={{ marginBottom: "6px", marginLeft: "10px" }}>
                        Diamond Club $500 to $999
                      </div>
                      <div style={{ marginBottom: "6px", marginLeft: "10px" }}>
                        Ruby Club $250 to $499
                      </div>
                      <div style={{ marginBottom: "6px", marginLeft: "10px" }}>
                        Sapphire Club $100 to $249
                      </div>
                      <div style={{ marginBottom: "6px", marginLeft: "10px" }}>
                        Emerald Club $25 to $99
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    AANP members contributions are voluntary. Contributions are
                    used for political purposes. The contribution amount shown
                    is only a suggestion. Members may give more of less than the
                    suggested amount or choose not to contribute. AANP will not
                    look upon any member with favor, or disfavor, because of the
                    amount of the contribution or a decision not to make a
                    contribution.
                    <br />
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <h4>Restrictions</h4>
                    The AANP-PAC accepts individual member contributions made by
                    personal check or credit card. The AANP-PAC can accept
                    contributions aggregating up to $5,000 per calendar year
                    from an individual member.{" "}
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <h4>Federal law prescribes the following:</h4>
                    <div style={{ marginLeft: "10px" }}>
                      <ol>
                        <li>
                          Contributions by corporations, foreign nationals and
                          federal contractos are prohibited.{" "}
                        </li>
                        <li>
                          Contributions to the AANP-PAC are not deductible as
                          charitable contributions for federal income tax
                          purposes.
                        </li>
                        <li>
                          Federal law requires AANP-PAC to report the name,
                          mailing address, occupation and employer for each
                          individual whose contributions aggregate in excess of
                          $200 in a calendar year.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    If you would like more information about AANP-PAC, please
                    visit pac.aanp.org, email{" "}
                    <a href="mailto=pac@aanp.org">pac@aanp.org</a>, or call
                    703-740-2529.{" "}
                  </div>
                </div>

                <div className="box-controls">
                  <button
                    className="button"
                    disabled={!_setPageComplete()}
                    onClick={() => _navigateToStep("opportunities")}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pac;
