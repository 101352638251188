import React from "react";

const NetworkForResearchModal = ({ closeModal }) => {
  return (
    <div class="modal-body">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-11">
          <h3>About Network for Research</h3>
        </div>

        <div className="cell small-1">
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
      </div>
      <p>
        By joining, you can gain exclusive benefits, such as a web-based
        platform to share resources and ideas with other NPs, a monthly
        newsletter with information on funding opportunities, upcoming
        conferences, research updates and much more! AANPNR also allows members
        to discuss collaborative projects with each other.
      </p>
    </div>
  );
};

export default NetworkForResearchModal;
