import React from "react";

const TopNav = ({
  currentStep,
  backwardStepDisabled,
  forwardStepDisabled,
  handleNavigate,
}) => {
  const steps = [
    "start",
    "contact-info",
    "member-type",
    "discounts",
    "opportunities",
    "account",
    "pac",
    "review",
  ];

  const index = () => {
    return steps.indexOf(currentStep);
  };

  const progress = () => {
    return Math.ceil((index() / (steps.length - 1)) * 100);
  };

  const prevStep = () => {
    if (index() > 0) {
      return steps[index() - 1];
    } else {
      return "";
    }
  };

  const nextStep = () => {
    if (index() < steps.length) {
      return steps[index() + 1];
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="box-progress">
        <div className="grid-x grid-margin-x align-middle">
          <div className="cell small-2">
            <button
              onClick={() => handleNavigate(prevStep())}
              className=""
              disabled={backwardStepDisabled}
              style={{
                color: backwardStepDisabled ? "#cccccc" : "rgb(12, 56, 99)",
                cursor: backwardStepDisabled ? "not-allowed" : "pointer",
                fontSize: "2rem",
                listStyle: "none",
                margin: "0",
                textAlign: "center",
                transform: "rotate(90deg)",
              }}
            >
              <span className="pager-icon icon-caret"></span>
              <span className="show-for-sr">Previous</span>
            </button>
          </div>
          <div className="cell small-8">
            You're signing up for: <strong>AANP membership</strong>
            <span className="progress">
              <span
                className="progress-bar"
                style={{ width: progress() + "%" }}
              ></span>
            </span>
          </div>
          <div className="cell small-2">
            <button
              onClick={() => handleNavigate(nextStep())}
              disabled={forwardStepDisabled}
              className=""
              style={{
                color: forwardStepDisabled ? "#cccccc" : "rgb(12, 56, 99)",
                cursor: forwardStepDisabled ? "not-allowed" : "pointer",
                fontSize: "2rem",
                listStyle: "none",
                margin: "0",
                textAlign: "center",
                transform: "rotate(-90deg)",
              }}
            >
              <span className="pager-icon icon-caret"></span>
              <span className="show-for-sr">Next</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
