import { UserManager } from "oidc-client";
import { authConfig } from "../data/config";

const userManager = new UserManager(authConfig);

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  userManager.signinRedirectCallback();
}

// export function signinRedirectCallback() {
//   return userManager.signinRedirectCallback();
// }

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export default userManager;
