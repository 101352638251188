import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import Modal from "react-modal";

import { getFellowsProduct } from "../api/membershipApi";
import { modalStyles } from "../styles/modalStyles";
import { UsStates, UsAndTerritoriesId } from "../data/data";
import { useMembership } from "../context/membershipContext";
import { isNavigationValid } from "../utils/functions";
import userManager from "../services/userService";

import AutoRenewModal from "../components/modals/AutoRenewModal";
import Campaigns from "../components/Campaigns";
import Payment from "../components/Payment";
import TopNav from "../components/ui/TopNav";
import Header from "../components/ui/Header";

const Review = (prop) => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();
  const [autoRenewModalIsOpen, setAutoRenewModalIsOpen] = useState(false);
  const pacDonationAmount = () => {
    if (isNaN(membership.pacDonation.amount)) {
      return 0;
    } else {
      return Number(membership.pacDonation.amount);
    }
  };
  const pacEligible = () => {
    return (
      membership.personBasic.isMember &&
      (UsAndTerritoriesId.some(
        (t) => t.countryID === parseInt(membership.address.countryID, 10)
      ) ||
        !UsStates.some((s) => s.stateAbb === membership.address.stateProvince))
    );
  };

  //console.log("membership", membership);

  useEffect(() => {
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    if (membership.personBasic.isFellow && membership.fellowsProduct.id === 0) {
      async function _getFellowsProduct() {
        let accesstoken = membership.accessToken;
        if (accesstoken === "") {
          const user = await userManager.signinRedirectCallback();
          if (typeof user === "undefined") {
            history.push("/error");
          } else {
            accesstoken = user.access_token;
          }
        }
        getFellowsProduct(accesstoken).then((r) => {
          if (r.success) {
            const fellowsProduct = r.data;
            dispatch({ type: "updateFellowsProduct", fellowsProduct });
          }
        });
      }
      _getFellowsProduct();
    }
  }, []);

  function _getTotal(totalType) {
    const baseMembershipPrice = parseInt(membership.memberType.price);
    let discountTotal = 0;
    let autoRenewTotal = 0;
    let campaignTotal = 0;

    if (membership.campaign.id > 0 && membership.campaign.discountAmount > 0) {
      campaignTotal = membership.campaign.discountAmount;
      discountTotal += campaignTotal;
    }

    if (membership.autoRenew && membership.autoRenewConfirm) {
      autoRenewTotal = baseMembershipPrice * 0.05;
      discountTotal += autoRenewTotal;
    }

    if (
      membership.veteranStatus === "active" ||
      membership.veteranStatus === "veteran"
    ) {
      discountTotal += 10;
    }

    const communityTotal = membership.selectedCommunities.length * 20;

    switch (totalType) {
      case "base":
        return baseMembershipPrice;

      case "autoRenew":
        return autoRenewTotal;

      case "discount":
        return discountTotal;

      case "sub":
        return (
          baseMembershipPrice + membership.fellowsProduct.price - discountTotal
        );

      case "grand":
        return (
          baseMembershipPrice +
          membership.fellowsProduct.price +
          communityTotal -
          discountTotal +
          pacDonationAmount()
        );

      default:
        return 0;
    }
  }

  function _removeAutoRenew() {
    let updatedMembership = membership;
    updatedMembership.autoRenew = "false";
    updatedMembership.autoRenewConfirm = false;
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  function _setAutoRenew(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedMembership = membership;
    switch (name) {
      case "autorenew":
        updatedMembership.autoRenew =
          parseInt(value, 10) === 1 ? "true" : "false";
        if (updatedMembership.autoRenew === "false") {
          updatedMembership.autoRenewConfirm = false;
          let errors = membership.errorList;
          errors = errors.filter((e) => e !== "autoRenew");
          dispatch({ type: "updateErrorList", errorList: errors });
        }
        break;

      case "autorenewConfirm":
        updatedMembership.autoRenewConfirm = e.target.checked;
        if (updatedMembership.autoRenewConfirm) {
          let errors = membership.errorList;
          errors = errors.filter((e) => e !== "autoRenew");
          dispatch({ type: "updateErrorList", errorList: errors });
        }
        break;

      default:
        break;
    }
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  function _setCampaignData(campaign) {
    const updatedMembership = membership;
    updatedMembership.campaign = campaign;
    dispatch({ type: "updateMembership", updatedMembership });
  }

  function _setCommunityData(id) {
    let selectedCommunities = membership.selectedCommunities;
    const newSelectedCommunities = selectedCommunities.filter(
      (c) => c !== parseInt(id, 10)
    );
    dispatch({
      type: "updateSelectedCommunities",
      selectedCommunities: newSelectedCommunities,
    });
    sessionStorage.setItem(
      "session_selectedCommunities",
      JSON.stringify(selectedCommunities)
    );
  }

  function _setDiscountData(name, value) {
    let updatedMembership = membership;
    switch (name) {
      case "networkForResearch":
        updatedMembership.opportunities.networkForResearch = value;
        break;

      case "npFinder":
        updatedMembership.opportunities.npFinder = value;
        break;

      default:
        break;
    }
    dispatch({ type: "updateMembership", updatedMembership });
  }

  //Navigation functions
  function _closeAutoRenewModal() {
    setAutoRenewModalIsOpen(false);
  }

  function _openAutoRenewModal(e) {
    e.preventDefault();
    setAutoRenewModalIsOpen(true);
  }

  function _navigateToPac() {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    history.push("/pac", { from: pathname, propState: "" });
  }

  if (
    membership.memberType.id === 0 ||
    !membership.personBasic.emailVerified ||
    membership.opportunities.networkForResearch === ""
  ) {
    history.push("/");
  }

  return (
    <>
      <Header isMember={membership.personBasic.isMember} />
      <div class="grid-container join-content" style={{ paddingTop: "10px" }}>
        <div class="grid-x grid-margin-x align-center">
          <div class="cell large-10 xlarge-9">
            <div class="box" style={{ maxWidth: "100%" }}>
              <div class="page is-active is-loaded">
                <TopNav
                  currentStep="review"
                  backwardStepDisabled={!pacEligible}
                  forwardStepDisabled={true}
                  handleNavigate={_navigateToPac}
                />
                <div class="box-content">
                  <div class="text-center box-content-intro">
                    <h2 class="box-content-heading">
                      Review and submit your order.
                    </h2>
                  </div>
                  <div class="box-section">
                    <h2>AANP Membership Costs</h2>
                    <p class="summary">
                      Costs associated directly with your AANP membership
                    </p>

                    <table class="review">
                      <thead class="show-for-sr">
                        <tr>
                          <th>Item</th>
                          <td>Price</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            {membership.memberType.displayName} <br />
                            <button
                              class="remove-button"
                              onClick={() => history.push("/member-type")}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >
                              Change
                            </button>
                          </th>
                          <td>
                            <strong>
                              <NumberFormat
                                value={_getTotal("base")}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                              {membership.memberType.isInternational && (
                                <span> USD</span>
                              )}
                            </strong>
                          </td>
                        </tr>
                        {membership.personBasic.isFellow &&
                          membership.fellowsProduct.id > 0 && (
                            <tr>
                              <th>{membership.fellowsProduct.productName}</th>
                              <td>
                                {" "}
                                <strong>
                                  <NumberFormat
                                    value={membership.fellowsProduct.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                  />
                                </strong>
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td colspan="2">
                            AANP Membership Discounts:
                            <table>
                              <thead class="show-for-sr">
                                <tr>
                                  <th>Item</th>
                                  <td>Price</td>
                                </tr>
                              </thead>
                              <tbody>
                                {(membership.veteranStatus !== "none" ||
                                  membership.veteranStatus === "") &&
                                  !membership.memberType.isInternational && (
                                    <tr>
                                      <th>Veterans' Discount</th>
                                      <td>
                                        <strong>- $10.00</strong>
                                      </td>
                                    </tr>
                                  )}

                                <Campaigns
                                  handleCampaignData={_setCampaignData}
                                  campaign={membership.campaign}
                                  personId={membership.personBasic.id}
                                  membershipProductId={membership.memberType.id}
                                  isInternational={
                                    membership.memberType.isInternational
                                  }
                                />
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        {membership.autoRenew && membership.autoRenewConfirm ? (
                          <tr>
                            <th>
                              Auto Renew Discount <br />
                              <button
                                class="remove-button"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1.3rem",
                                  fontWeight: "bold",
                                  textDecoration: "underline",
                                }}
                                onClick={() => _removeAutoRenew()}
                              >
                                Remove
                              </button>
                            </th>
                            <td>
                              <strong>
                                -{" "}
                                <NumberFormat
                                  value={_getTotal("autoRenew")}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                                {membership.memberType.isInternational && (
                                  <span> USD</span>
                                )}
                              </strong>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {membership.memberType.eligibleForAutoRenewal && (
                              <tr class="review-callout">
                                <td colspan="2">
                                  <h3 class="text-center medium-text-left">
                                    Save 5% on your membership.
                                  </h3>
                                  <div class="grid-x grid-margin-x align-middle">
                                    <div class="cell large-8 text-center medium-text-left">
                                      <legend
                                        class="labelHeading labelHeading--light"
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        Would you like to sign up for
                                        Auto-Renewal and receive{" "}
                                        <strong>5% off</strong> today’s
                                        membership cost?
                                      </legend>
                                      <button
                                        onClick={_openAutoRenewModal}
                                        className="modal-link"
                                      >
                                        Tell me more.
                                      </button>
                                    </div>
                                    <div class="cell large-4 radioButton-wrapper">
                                      <span class="input-wrapper">
                                        <input
                                          type="radio"
                                          required
                                          id="autorenew_discounts_yes"
                                          data-show="autoRenewdiscounts"
                                          value="1"
                                          name="autorenew"
                                          class="radioButton"
                                          checked={
                                            membership.autoRenew === "true"
                                          }
                                          onChange={_setAutoRenew}
                                        />
                                        <label for="autorenew_discounts_yes">
                                          Yes
                                        </label>
                                      </span>
                                      <span class="input-wrapper">
                                        <input
                                          type="radio"
                                          required
                                          id="autorenew_discounts_no"
                                          data-show="autoRenewdiscounts"
                                          value="0"
                                          name="autorenew"
                                          class="radioButton"
                                          checked={
                                            membership.autoRenew === "false"
                                          }
                                          onChange={_setAutoRenew}
                                        />
                                        <label for="autorenew_discounts_no">
                                          No
                                        </label>
                                      </span>
                                    </div>
                                  </div>
                                  {membership.autoRenew === "true" && (
                                    <>
                                      <fieldset
                                        class="input-wrapper"
                                        style={{ paddingTop: "20px" }}
                                      >
                                        <input
                                          type="checkbox"
                                          name="autorenewConfirm"
                                          id="autorenewConfirm_review"
                                          checked={membership.autoRenewConfirm}
                                          onChange={_setAutoRenew}
                                        />
                                        <label
                                          for="autorenewConfirm_review"
                                          class="labelCheckbox"
                                        >
                                          <p>
                                            I opt to auto-renew my membership in
                                            accordance with the{" "}
                                            <a href="/" target="_blank">
                                              Recurring Payment Authorization
                                            </a>{" "}
                                            terms and save 5% on regular
                                            membership dues as long as I am
                                            auto-renewing.{" "}
                                          </p>
                                          <p>
                                            <strong>
                                              By checking the Auto-Renewal box,
                                              you are authorizing AANP to charge
                                              your credit or debit card
                                              annually.
                                            </strong>
                                          </p>
                                        </label>
                                      </fieldset>
                                      {membership.errorList.includes(
                                        "autoRenew"
                                      ) && (
                                        <div className="error-class-email">
                                          Please confirm that you are opting in
                                          for auto-renew
                                        </div>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            )}{" "}
                          </>
                        )}

                        <tr class="review-summary">
                          <th>AANP membership Cost Sub-Total:</th>
                          <td>
                            <NumberFormat
                              value={_getTotal("sub")}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                            {membership.memberType.isInternational && (
                              <span> USD</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {(membership.opportunities.npFinder === "true" ||
                      membership.opportunities.networkForResearch === "true" ||
                      membership.selectedCommunities.length > 0) && (
                      <>
                        <h2>Additional Opportunities</h2>
                        <p class="summary">
                          Options you have selected in addition to AANP
                          Membership.
                        </p>

                        <table class="review">
                          <thead class="show-for-sr">
                            <tr>
                              <th>Item</th>
                              <td>Price</td>
                            </tr>
                          </thead>
                          <tbody>
                            {membership.opportunities.networkForResearch ===
                              "true" && (
                              <tr>
                                <th>
                                  Network for Research
                                  <br />
                                  <button
                                    class="remove-button"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      _setDiscountData(
                                        "networkForResearch",
                                        "false"
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                </th>
                                <td>
                                  <strong>
                                    $0.00{" "}
                                    {membership.memberType.isInternational && (
                                      <span>USD</span>
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            )}

                            {membership.opportunities.npFinder === "true" && (
                              <tr>
                                <th>
                                  NP Finder
                                  <br />
                                  <button
                                    class="remove-button"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      _setDiscountData("npFinder", "false")
                                    }
                                  >
                                    Remove
                                  </button>
                                </th>
                                <td>
                                  <strong>
                                    $0.00{" "}
                                    {membership.memberType.isInternational && (
                                      <span>USD</span>
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            )}

                            {membership.selectedCommunities.length > 0 && (
                              <>
                                {membership.selectedCommunities.map((item) => {
                                  return (
                                    <tr>
                                      <th>
                                        {
                                          membership.communities.find(
                                            (c) => c.id === parseInt(item, 10)
                                          ).name
                                        }
                                        <br />
                                        <button
                                          class="remove-button"
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "1.3rem",
                                            fontWeight: "bold",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() =>
                                            _setCommunityData(item)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </th>
                                      <td>
                                        <strong>
                                          $20.00
                                          {membership.memberType
                                            .isInternational && (
                                            <span> USD</span>
                                          )}
                                        </strong>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                      </>
                    )}

                    {pacDonationAmount() > 0 && (
                      <>
                        <h2>The AANP Political Action Committee (AANP-PAC)</h2>
                        <p class="summary">Your contribution to AANP-PAC</p>

                        <table class="review">
                          <thead class="show-for-sr">
                            <tr>
                              <th>Item</th>
                              <td>Price</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>AANP-PAC Contribution</td>
                              <td>
                                <NumberFormat
                                  value={pacDonationAmount()}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>

                  <div class="box-section-footer">
                    <div class="grid-x grid-margin-x">
                      <div class="cell medium-8 small-7">Total Amount Due:</div>
                      <div class="cell medium-4 small-5 text-right">
                        <NumberFormat
                          value={_getTotal("grand")}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                        {membership.memberType.isInternational && (
                          <span> USD</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <Payment />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={autoRenewModalIsOpen}
        onRequestClose={_closeAutoRenewModal}
        style={modalStyles}
        contentLabel="Auto Renew"
      >
        <AutoRenewModal closeModal={_closeAutoRenewModal} />
      </Modal>
    </>
  );
};

export default Review;
