import React, { useContext, useReducer } from "react";
import { captureMessage } from "@sentry/react";

import membershipReducer from "../reducers/reducer";
import * as _initialValues from "./initialValues";
export const MembershipContext = React.createContext(null);

let initialMembership;
const storedMembership = JSON.parse(
  sessionStorage.getItem("session_membership")
);
try {
  if (storedMembership !== null) {
    initialMembership = storedMembership;
  } else {
    initialMembership = _initialValues.membership;
  }
} catch {
  captureMessage("The membership could not be parsed into JSON");
  initialMembership = { memberType: 0 };
}

export function MembershipProvider(props) {
  const [membership, dispatch] = useReducer(
    membershipReducer,
    initialMembership
  );

  const contextValue = { membership, dispatch };
  return (
    <MembershipContext.Provider value={contextValue}>
      {props.children}
    </MembershipContext.Provider>
  );
}

export function useMembership() {
  const context = useContext(MembershipContext);
  return context;
}
