import React, { useState } from "react";
import { signinRedirect } from "../services/userService";
import { useMembership } from "../context/membershipContext";
import { useHistory } from "react-router-dom";
import { welcomeText } from "../data/config";

import Spinner from "../components/ui/Spinner";
import Header from "../components/ui/Header";

const Login = (props) => {
  const { membership } = useMembership();
  const [isSearching, setIsSearching] = useState(false);
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 400px)").matches;
  const marginTop = {
    marginTop: isMobile ? "20px" : "",
  };

  function _login() {
    try {
      setIsSearching(true);
      signinRedirect();
    } catch (error) {
      history.push("/error");
    }
  }

  if (!membership.personBasic.email) {
    history.push("/start");
  }

  return (
    <>
      <Header isMember={membership.personBasic.isMember} />
      <div style={{ position: "relative" }}>
        <div
          className="grid-container"
          id={isMobile ? "join-banner" : "not-mobile"}
        >
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div style={{ paddingBottom: "4rem" }}>{welcomeText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container join-content">
        <div className="grid-x grid-margin-x align-center" style={marginTop}>
          <div className="cell large-10 xlarge-9">
            <div className="box">
              <div className="page is-active is-loaded">
                <div className="box-header">
                  <legend>
                    To begin, please enter your email address below:
                  </legend>
                </div>
                <div className="box-content" style={{ height: "22em" }}>
                  <fieldset className="box-section">
                    {isSearching ? (
                      <Spinner />
                    ) : (
                      <>
                        <h3 className="box-section-heading">
                          Looks Like We Already Know You!
                        </h3>
                        <p className="input-wrapper">
                          The email address{" "}
                          <strong>
                            <em>{membership.personBasic.email}</em>
                          </strong>{" "}
                          is already in our system. To continue with the
                          process, we would ask that you first login.
                        </p>

                        <div className="text-center">
                          <button className="button" onClick={() => _login()}>
                            Login
                          </button>
                        </div>
                      </>
                    )}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
