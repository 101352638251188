import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMembership } from "../context/membershipContext";
import { modalStyles } from "../styles/modalStyles";
import { isNavigationValid } from "../utils/functions";

import AutoRenewModal from "../components/modals/AutoRenewModal";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import TopNav from "../components/ui/TopNav";
import Header from "../components/ui/Header";

const Discounts = (prop) => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();
  const [autoRenewModalIsOpen, setAutoRenewModalIsOpen] = useState(false);

  //If the member type selected in the previous step is not eligible for auto renew, reset auto renew state values to default
  //on page load. This is in case the user navigated backwards and changed member types to one that is not eligible
  useEffect(() => {
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    if (membership.memberType.isInternational) {
      history.push("/opportunities", { from: pathname, propState: "" });
    }

    if (!membership.memberType.eligibleForAutoRenewal) {
      let updatedMembership = membership;
      updatedMembership.autoRenew = "";
      updatedMembership.autoRenewConfirm = false;
      dispatch({
        type: "updateMembership",
        updatedMembership,
      });
    }
  }, []);

  //Navigate either forward to opportunities, or backward to member-type
  //Save discount info to session storage before navigating
  function _navigateToStep(step) {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    history.push(step, { from: pathname, propState: "" });
  }

  //Stock function to set app storage values based on user input
  function _setDiscountData(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedMembership = membership;

    switch (name.trim()) {
      case "veteran":
        updatedMembership.veteranStatus = value;
        break;

      case "autorenew":
        updatedMembership.autoRenew = value;
        if (updatedMembership.autoRenew === "false") {
          updatedMembership.autoRenewConfirm = false;
        }
        break;

      case "autorenewConfirm":
        updatedMembership.autoRenewConfirm = e.target.checked;
        break;

      default:
        break;
    }
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  //Validate to make sure that all values have been set before user can nav forward
  function _setPageComplete() {
    const veteranStatusComplete = membership.veteranStatus !== "";
    const autoRenewComplete =
      membership.autoRenew !== "" &&
      (membership.autoRenew === "false" ||
        (membership.autoRenew === "true" && membership.autoRenewConfirm));

    return (
      veteranStatusComplete &&
      (!membership.memberType.eligibleForAutoRenewal || autoRenewComplete)
    );
  }

  //Stock functions to open and close the modal (Tell me more modal about auto-renew)
  function _closeAutoRenewModal() {
    setAutoRenewModalIsOpen(false);
  }

  function _openAutoRenewModal(e) {
    e.preventDefault();
    setAutoRenewModalIsOpen(true);
  }

  return (
    <>
      <Header isMember={membership.personBasic.isMember} />

      <div
        className="grid-container join-content"
        style={{ paddingTop: "10px" }}
      >
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <TopNav
                  currentStep="discounts"
                  backwardStepDisabled={false}
                  forwardStepDisabled={!_setPageComplete()}
                  handleNavigate={_navigateToStep}
                />
              </div>
              <div className="box-content">
                <div className="text-center box-content-intro">
                  <h2 className="box-content-heading">
                    Your member type will be:{" "}
                    {membership.memberType.displayName}
                    <strong></strong>
                  </h2>
                  <p>
                    Your base annual membership fee is:
                    <span className="textHighlight">
                      <NumberFormat
                        value={membership.memberType.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />{" "}
                      {membership.memberType.isInternational && (
                        <span>USD</span>
                      )}
                    </span>
                  </p>
                  <p>
                    Let's see if you're eligible for any membership savings
                    below!
                  </p>
                </div>

                <form>
                  <fieldset className="box-section">
                    <div className=" text-center medium-text-left">
                      <legend className="labelHeading labelHeading--light">
                        Are you currently serving in the military or have you
                        served in the military? *
                      </legend>
                    </div>
                    <div className="radioFancy-wrapper">
                      <div className="radioFancy">
                        <input
                          id="military_yes"
                          required
                          name="veteran"
                          type="radio"
                          className="radioFancy-input"
                          value="active"
                          checked={membership.veteranStatus === "active"}
                          onChange={_setDiscountData}
                        />
                        <label
                          htmlFor="military_yes"
                          className="radioFancy-label"
                        >
                          Yes, I am currently serving in the military.
                        </label>
                      </div>

                      <div className="radioFancy">
                        <input
                          id="veteran_yes"
                          required
                          name="veteran"
                          type="radio"
                          className="radioFancy-input"
                          value="veteran"
                          checked={membership.veteranStatus === "veteran"}
                          onChange={_setDiscountData}
                        />
                        <label
                          htmlFor="veteran_yes"
                          className="radioFancy-label"
                        >
                          Yes, I am a Veteran.
                        </label>
                      </div>

                      <div className="radioFancy">
                        <input
                          id="veteran_no"
                          required
                          name="veteran"
                          type="radio"
                          className="radioFancy-input"
                          value="none"
                          checked={membership.veteranStatus === "none"}
                          onChange={_setDiscountData}
                        />
                        <label
                          htmlFor="veteran_no"
                          className="radioFancy-label"
                          f
                        >
                          No, I have not served in the military.
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  {membership.memberType.eligibleForAutoRenewal && (
                    <fieldset className="box-section">
                      <div className="grid-x grid-margin-x align-middle">
                        <div className="cell large-8 text-center medium-text-left">
                          <legend
                            className="labelHeading labelHeading--light"
                            style={{ marginBottom: "1rem" }}
                          >
                            Would you like to enroll in Auto-Renewal and{" "}
                            <strong>
                              save 5% on today’s membership fees and future
                              renewals
                            </strong>{" "}
                            thru Auto-Renewal? Save time and money!
                          </legend>
                          <button
                            onClick={_openAutoRenewModal}
                            className="modal-link auto-renew-tell"
                          >
                            Tell me more.
                          </button>
                        </div>
                        <div className="cell large-4 radioButton-wrapper">
                          <span className="input-wrapper auto-renew-yes">
                            <input
                              type="radio"
                              required
                              id="autorenew_discounts_yes"
                              data-show="autoRenewdiscounts"
                              value="true"
                              name="autorenew"
                              className="radioButton"
                              checked={membership.autoRenew === "true"}
                              onChange={_setDiscountData}
                              disabled={
                                membership.memberType.disabledReason ===
                                "Auto-Renewal"
                              }
                            />
                            <label htmlFor="autorenew_discounts_yes">Yes</label>
                          </span>
                          <span className="input-wrapper">
                            <input
                              type="radio"
                              required
                              id="autorenew_discounts_no"
                              data-show="autoRenewdiscounts"
                              value="false"
                              name="autorenew"
                              className="radioButton"
                              checked={membership.autoRenew === "false"}
                              onChange={_setDiscountData}
                              disabled={
                                membership.memberType.disabledReason ===
                                "Auto-Renewal"
                              }
                            />
                            <label htmlFor="autorenew_discounts_no">No</label>
                          </span>
                        </div>
                      </div>
                      {membership.autoRenew === "true" && (
                        <fieldset
                          className="input-wrapper"
                          style={{ paddingTop: "20px" }}
                        >
                          <input
                            type="checkbox"
                            name="autorenewConfirm"
                            id="autorenewConfirm_discounts"
                            checked={membership.autoRenewConfirm}
                            onChange={_setDiscountData}
                            value="1"
                            disabled={
                              membership.memberType.disabledReason ===
                              "Auto-Renewal"
                            }
                          />
                          <label
                            htmlFor="autorenewConfirm_discounts"
                            className="labelCheckbox"
                          >
                            <p>
                              I opt to auto-renew my membership in accordance
                              with the{" "}
                              <a href="/" target="_blank">
                                Recurring Payment Authorization
                              </a>{" "}
                              terms and save 5% on regular membership dues as
                              long as I am auto-renewing.{" "}
                            </p>
                            <p>
                              <strong>
                                By checking the Auto-Renewal box, you are
                                authorizing AANP to charge your credit or debit
                                card annually.
                              </strong>
                            </p>
                          </label>
                        </fieldset>
                      )}
                    </fieldset>
                  )}
                  <div className="box-controls">
                    <button
                      className="button"
                      type="button"
                      disabled={!_setPageComplete()}
                      onClick={() => _navigateToStep("/opportunities")}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={autoRenewModalIsOpen}
        onRequestClose={_closeAutoRenewModal}
        style={modalStyles}
        contentLabel="Auto Renew"
      >
        <AutoRenewModal closeModal={_closeAutoRenewModal} />
      </Modal>
    </>
  );
};

export default Discounts;
