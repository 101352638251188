import * as _initialValues from "../context/initialValues";
//import { MembershipProvider } from "../context/membershipContext";

export default function membershipReducer(membership, action) {
  switch (action.type) {
    case "resetMembership":
      return _initialValues.membership;

    case "updateAccessToken":
      const { accessToken } = action;
      return {
        ...membership,
        accessToken: accessToken,
      };

    case "updateCampaign":
      const { campaign } = action;
      return {
        ...membership,
        campaign: campaign,
      };

    case "updateCommunities":
      const { communities } = action;
      return {
        ...membership,
        communities: communities,
      };

    case "updateCountries":
      const { countries } = action;
      return {
        ...membership,
        countries: countries,
      };

    case "updateDemographics":
      const { demographics } = action;
      return {
        ...membership,
        demographics: demographics,
      };

    case "updateErrorList":
      const { errorList } = action;
      return {
        ...membership,
        errorList: errorList,
      };

    case "updateFellowsProduct":
      const { fellowsProduct } = action;
      return {
        ...membership,
        fellowsProduct: fellowsProduct,
      };

    case "updateMembership":
      const { updatedMembership } = action;
      return {
        ...membership,
        updatedMembership,
      };

    case "updateMemberTypes":
      const { memberTypes } = action;
      return {
        ...membership,
        memberTypes: memberTypes,
      };

    case "updateOrderCompleteData":
      const { orderStatus, orderId, loginUrl } = action;
      return {
        ...membership,
        orderStatus: orderStatus,
        orderId: orderId,
        loginUrl: loginUrl,
      };

    case "updatePersonBasicData":
      const { personBasic } = action;
      return {
        ...membership,
        personBasic: personBasic,
      };

    case "updatePersonIsComplete":
      const { personIsComplete } = action;
      return {
        ...membership,
        personIsComplete: personIsComplete,
        password: "",
        passwordConfirm: "",
      };

    case "updateSchools":
      const { schools } = action;
      return {
        ...membership,
        schools: schools,
      };

    case "updateSelectedCommunities":
      const { selectedCommunities } = action;
      return {
        ...membership,
        selectedCommunities: selectedCommunities,
      };

    case "updateStagedCommunities":
      const { stagedCommunities } = action;
      return {
        ...membership,
        stagedCommunities: stagedCommunities,
      };

    case "updateStateProvinces":
      const { states } = action;
      return {
        ...membership,
        states: states,
      };

    default:
      throw new Error("Unhandled action " + action.type);
  }
}
