import axios from "axios";
import { apiUrl } from "../data/config";
import { handleError } from "../utils/functions";

const config = {
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

export function getAllCommunities() {
  return axios
    .get(apiUrl + `membership/getallcommunities`, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting all communities");
    });
}

export function getAllCountries() {
  return axios
    .get(apiUrl + `membership/getallcountries`, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting all countries");
    });
}

export function getAllSchools() {
  return axios
    .get(apiUrl + `membership/getallschools`, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting all schools");
    });
}

export async function getAvailableMemberTypes(
  id,
  countryId,
  accesstoken
) {
  if (id > 0 && accesstoken.length > 0) {
    return axios
      .get(apiUrl + `membership/getreturnmembertypes/${countryId}`, {
        headers: { Authorization: `Bearer ${accesstoken}` },
      })
      .then((response) => response.data)
      .catch((error) => {
        return handleError(error, "Error getting all return member types");
      });
  } else {
    return axios
      .get(apiUrl + `membership/getjoinmembertypes/${countryId}`, config)
      .then((response) => response.data)
      .catch((error) => {
        return handleError(error, "Error getting join member types");
      });
  }
}

export async function getCampaignByCode(campaignRequest) {
  return axios
    .post(apiUrl + `membership/getcampaignbycode`, campaignRequest, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting campaign code");
    });
}

export async function getFellowsProduct(token) {
  return axios
    .get(apiUrl + `membership/getfellowsproduct`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting fellows product by id");
    });
}

export async function getStateProvinces(name) {
  return axios
    .get(apiUrl + `membership/getstateprovinces/${name}`, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting all states");
    });
}

export function submitOrder(order) {
  return axios
    .post(apiUrl + `membership/submitorder`, order, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error posting order");
    });
}
