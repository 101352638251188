import * as Sentry from "@sentry/react";
import { validNavigations } from "../data/config";

export function handleError(error, msg) {
  Sentry.captureMessage(msg);
  Sentry.captureException(error);
  return {
    success: false,
    errorMessages: [msg],
    data: [],
  };
}

export function isNavigationValid(prop, path) {
  if (typeof prop === "undefined") {
    return false;
  }

  if (typeof prop.location.state === "undefined") {
    return false;
  }

  const from = prop.location.state.from;
  const navPaths = validNavigations[path];
  return navPaths.includes(from);
}

export function _setErrorClass(errors, fieldName, isLabel) {
  if (errors.length > 0) {
    if (errors.includes(fieldName)) {
      return isLabel ? "error-class-label" : "error-class";
    }
    return "";
  }
}
