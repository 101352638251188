import React, { useEffect } from "react";
import Header from "../components/ui/Header";
import { welcomeText } from "../data/config";

const SignOut = () => {
  useEffect(() => {
    sessionStorage.clear();
  });

  return (
    <>
      <Header isMember={false} />
      <div style={{ position: "relative" }}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div style={{ paddingBottom: "4rem" }}>{welcomeText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container join-content">
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box">
              <div className="page is-active is-loaded">
                <div className="box-header">
                  <legend>See you next time!</legend>
                </div>
                <div className="box-content">
                  <fieldset className="box-section">
                    <h3 className="box-section-heading">
                      You have successfully logged out of your AANP account.{" "}
                    </h3>
                    <p className="input-wrapper">
                      If you have any comments or questions, please contact our
                      membership department at (512) 505-0242 or by email at{" "}
                      <a href="mailto:membership@aanp.org">
                        membership@aanp.org
                      </a>
                      .
                    </p>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignOut;
