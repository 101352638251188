import React, { useEffect } from "react";
import Header from "../components/ui/Header";
import { welcomeText } from "../data/config";
import { membership } from "../context/initialValues";
import { useMembership } from "../context/membershipContext";

const NotAvailable = () => {
  const { dispatch } = useMembership();
  const isMobile = window.matchMedia("(max-width: 750px)").matches;
  const marginTop = {
    marginTop: isMobile ? "20px" : "",
  };

  useEffect(() => {
    sessionStorage.clear();
    dispatch({ type: "updateMembership", updatedMembership: membership });
  }, []);

  return (
    <>
      <Header isMember={false} />
      <div style={{ position: "relative" }}>
        <div className="grid-container" id={isMobile ? "join-banner" : ""}>
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div style={{ paddingBottom: "4rem" }}>{welcomeText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container join-content">
        <div className="grid-x grid-margin-x align-center" style={marginTop}>
          <div className="cell large-10 xlarge-9">
            <div className="box">
              <div className="page is-active is-loaded">
                <div className="box-header">
                  <legend>Please Contact AANP</legend>
                </div>
                <div className="box-content">
                  <fieldset className="box-section">
                    <h3 className="box-section-heading">
                      This account is currently unavailable{" "}
                    </h3>
                    <p className="input-wrapper">
                      We apologize for the inconvenience, but the account
                      associated with this email address is currently not
                      available. Please know that you are very important to us;
                      to resolve this matter, please contact our membership
                      department at (512) 505-0242 or by email at{" "}
                      <a href="mailto:membership@aanp.org">
                        membership@aanp.org
                      </a>
                      .
                    </p>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotAvailable;
