import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { captureMessage } from "@sentry/minimal";
import Cookies from "js-cookie";

import * as _initialValues from "../context/initialValues";
import { useMembership } from "../context/membershipContext";
import { getPersonByEmail } from "../api/personApi";
import { signinRedirect } from "../services/userService";
import { welcomeText } from "../data/config";

import Spinner from "../components/ui/Spinner";
import Header from "../components/ui/Header";

const Start = () => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();
  const [emailError, setEmailError] = useState(false);
  const [isSearching, setIsSearching] = useState(true);

  const isMobile = window.matchMedia("(max-width: 750px)").matches;
  const marginTop = {
    marginTop: isMobile ? "20px" : "",
  };
  // const validateEmail = (email) => {
  //   return String(email)
  //     .toLowerCase()
  //     .match(
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //     );
  // };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
  };

  //This will need to run in the same domain as the cookie in order to work
  useEffect(() => {
    async function _verifyAuthCookie() {
      const authCookie = Cookies.get("Auth");
      if (typeof authCookie !== "undefined") {
        signinRedirect();
      } else {
        if (membership.personBasic.id > 0) {
          sessionStorage.clear();
          dispatch({ type: "resetMembership" });
        }
        setIsSearching(false);
      }
    }
    _verifyAuthCookie();
  }, []);

  function _setInputInfo(e) {
    let personBasic = membership.personBasic;
    personBasic.email = e.target.value;
    dispatch({ type: "updatePersonBasicData", personBasic: personBasic });
    setEmailError(false);
  }

  async function _verifyEmail(e) {
    if (e) {
      e.preventDefault();
    }

    setIsSearching(true);

    if (!validateEmail(membership.personBasic.email)) {
      setEmailError(true);
      setIsSearching(false);
    } else {
      getPersonByEmail(membership.personBasic.email).then((response) => {
        if (response.success) {
          let updatedMembership = _initialValues.membership;
          updatedMembership.personBasic.emailVerified = true;
          updatedMembership.personBasic.isInSystem = response.data.isInSystem;
          updatedMembership.personBasic.disabled = response.data.disabled;
          dispatch({
            type: "updateMembership",
            updatedMembership,
          });

          sessionStorage.setItem(
            "session_membership",
            JSON.stringify(updatedMembership)
          );

          if (response.data.disabled) {
            history.push("/not-available", { from: pathname, propState: "" });
          } else if (response.data.isInSystem) {
            history.push("/login", { from: pathname, propState: "" });
          } else {
            history.push("/contact-info", { from: pathname, propState: "" });
          }
        } else {
          captureMessage(
            `Could not verify email ${membership.personBasic.email}`
          );
          history.push("/error");
        }
      });
    }
  }

  //If someone attempts to navigate back to this page after the order is complete
  if (membership.personIsComplete) {
    history.push("/recent", { from: pathname, propState: "" });
  }

  return (
    <>
      <div
        style={{ position: "relative", backgroundColor: "#ffffff!important" }}
      >
        <div
          className="grid-container"
          id={isMobile ? "join-banner" : "not-mobile"}
        >
          <Header isMember={membership.personBasic.isMember} />
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div
                style={{
                  paddingBottom: "4rem",
                }}
              >
                {welcomeText}
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={_verifyEmail}>
        <div className="grid-container join-content">
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-10 xlarge-9">
              <div className="box">
                <div className="page is-active is-loaded" style={marginTop}>
                  <div className="box-header">
                    <legend>
                      To begin, please enter your email address below:
                    </legend>
                  </div>
                  <div className="box-content" style={{ height: "20em" }}>
                    <fieldset className="box-section">
                      {isSearching ? (
                        <Spinner height={"150px"} />
                      ) : (
                        <>
                          <div className=" text-center medium-text-left">
                            <p className="input-wrapper">
                              <label
                                htmlFor="email"
                                className={
                                  emailError ? "error-class-label" : ""
                                }
                              >
                                Email Address *{" "}
                                <span
                                  className={
                                    "helper " +
                                    (emailError ? "error-class-label" : "")
                                  }
                                >
                                  (Your email address will be your account
                                  login)
                                </span>
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                aria-required="true"
                                autoComplete="email"
                                value={membership.personBasic.email}
                                className={emailError ? "error-class" : ""}
                                onChange={_setInputInfo}
                              />
                              {emailError && (
                                <div className="error-class-email">
                                  Please enter a valid email address
                                </div>
                              )}
                            </p>
                          </div>
                          <div
                            className="text-center"
                            style={{ marginTop: "20px" }}
                          >
                            <button
                              type="submit"
                              className="button"
                              disabled={
                                membership.personBasic.email.length === 0
                              }
                            >
                              Get Started
                            </button>
                          </div>
                        </>
                      )}{" "}
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Start;
