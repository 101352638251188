import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMembership } from "../context/membershipContext";

import { duesText } from "../data/config";
import { addressErrors } from "../data/data";
import { getAllCountries, getAllCommunities } from "../api/membershipApi";
import {
  getAddressErrors,
  getPersonBasicErrors,
  getPhoneErrors,
} from "../services/validationService";
import { isNavigationValid } from "../utils/functions";

import Address from "../components/Address";
import Phone from "../components/Phone";
import Spinner from "../components/ui/Spinner";
import TopNav from "../components/ui/TopNav";
import Header from "../components/ui/Header";

const ContactInfo = (prop) => {
  const { membership, dispatch } = useMembership();
  const { pathname } = useLocation();
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    //If a user tries to navigate directly to this page, push them back to /start; in fact a user should only get
    //to this page from other parts of the app
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    try {
      //On load, get countries if countries and communities if they do not already exist
      //The countries list is so large, it overwhelms sessionStorage, so it needs to go into localStorage
      const storedCountries = localStorage.getItem("aanp_countries");
      //if (Object.keys(JSON.parse(storedCountries)).length === 0) {
      if (storedCountries == null) {
        setIsSearching(true);
        async function getCountries() {
          getAllCountries().then((response) => {
            if (response.success) {
              setCountries(response.list);
              localStorage.setItem(
                "aanp_countries",
                JSON.stringify(response.list)
              );
            } else {
              history.push("/error");
            }
          });
        }
        getCountries();
      } else {
        if (countries.length === 0) {
          setCountries(JSON.parse(storedCountries));
        }
      }

      if (membership.communities.length === 0) {
        setIsSearching(true);
        async function getCommunities() {
          getAllCommunities().then((response) => {
            if (response.success) {
              dispatch({
                type: "updateCommunities",
                communities: response.list,
              });
            } else {
              history.push("/error");
            }
          });
        }
        getCommunities();
      }
    } catch (error) {
      history.push("/error");
    }

    setIsSearching(false);
  }, []);

  //When either hitting the Continue button, or the forward nav arrow, errors are collected
  function _getPageErrors() {
    let addressErrors = getAddressErrors(
      membership.address,
      countries,
      membership.states
    );
    let personBasicErrors = getPersonBasicErrors(membership.personBasic);
    let phoneErrors = getPhoneErrors(membership.phone);
    return addressErrors.concat(personBasicErrors, phoneErrors);
  }

  //Stock function to remove field from error list
  function _removeErrorClass(name) {
    let errors = membership.errorList;
    let newErrors = errors.filter((e) => e !== name);
    if (name === "email") {
      newErrors = newErrors.filter((e) => e !== "emailInvalid");
    }
    if (name === "address") {
      newErrors = errors.filter((e) => addressErrors.indexOf(e) < 0);
    }
    dispatch({ type: "updateErrorList", errorList: newErrors });
  }

  //Stock function to change formatting of form field based on whether it is in the error list or not
  //Can this be centralized?
  function _setErrorClass(fieldName, isLabel) {
    if (membership.errorList.length > 0) {
      if (membership.errorList.includes(fieldName)) {
        return isLabel ? "error-class-label" : "error-class";
      }
      return "";
    }
  }

  //Stock function to set app storage values based on user input
  function _setGeneralInfo(e) {
    const name = e.target.name;
    const value = e.target.value;

    let updatedMembership = membership;
    updatedMembership.personBasic[name] = value;

    _removeErrorClass(name);
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  //Function to validate if form data is correct; if valid, nav forward, it invalid display errors
  function _validatePerson(e) {
    if (e) {
      e.preventDefault();
    }

    setIsSearching(true);
    const errors = _getPageErrors();
    if (errors.length === 0) {
      _navigateToStep("/member-type");
    } else {
      dispatch({ type: "updateErrorList", errorList: errors });
      setIsSearching(false);
    }
  }

  //Navigate either forward to membership types, or backward to email input
  //Save membership info to session storage before navigating
  function _navigateToStep(step) {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    history.push(step, { from: pathname, propState: "" });
  }

  return (
    <>
      {isSearching ? (
        <Spinner />
      ) : (
        <>
          <Header isMember={membership.personBasic.isMember} />
          <div
            className="grid-container join-content"
            style={{ paddingTop: "10px" }}
          >
            <div className="grid-x grid-margin-x align-center">
              <div className="cell large-10 xlarge-9">
                <div className="box" style={{ maxWidth: "100%" }}>
                  <div className="page is-active is-loaded">
                    <TopNav
                      currentStep="contact-info"
                      backwardStepDisabled={
                        membership.personBasic.isInSystem ||
                        membership.personBasic.id > 0
                      }
                      forwardStepDisabled={_getPageErrors().length > 0}
                      handleNavigate={_navigateToStep}
                    />
                    <div className="box-content">
                      <div className="text-center">
                        {membership.personIsComplete ? (
                          <div className="box-content-intro">
                            <h2 className="box-content-heading">
                              Your account data has been saved. You will have a
                              chance to edit your account data once your
                              membership has been completed.
                            </h2>
                          </div>
                        ) : (
                          <div className="box-content-intro">
                            <h2>
                              {membership.personBasic.id > 0 ? (
                                <span>
                                  Let's verify your account information
                                </span>
                              ) : (
                                <span>Now let's create your account</span>
                              )}{" "}
                            </h2>
                            <span className="helper">
                              * Indicates a required field.
                            </span>
                          </div>
                        )}
                      </div>

                      {isSearching ? (
                        <Spinner />
                      ) : (
                        <>
                          <form
                            onSubmit={(e) => {
                              _validatePerson(e);
                            }}
                          >
                            <div className="box-section">
                              <h3 className="box-section-heading">
                                General Information
                              </h3>
                              <div className="grid-x grid-margin-x">
                                <div className="cell medium-4">
                                  <p
                                    className="input-wrapper"
                                    style={{ marginBottom: "1.3rem" }}
                                  >
                                    <label
                                      htmlFor="firstName"
                                      className={_setErrorClass(
                                        "firstName",
                                        true
                                      )}
                                    >
                                      First Name *
                                    </label>
                                    <input
                                      type="text"
                                      id="firstName"
                                      name="firstName"
                                      aria-required="true"
                                      value={membership.personBasic.firstName}
                                      onChange={_setGeneralInfo}
                                      maxLength="50"
                                      autoComplete="given-name"
                                      className={_setErrorClass(
                                        "firstName",
                                        false
                                      )}
                                      disabled={membership.personIsComplete}
                                    />
                                  </p>
                                  {membership.errorList.includes(
                                    "firstName"
                                  ) && (
                                    <div
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "red",
                                        fontWeight: "bold",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      First Name Is required.
                                    </div>
                                  )}
                                </div>
                                <div className="cell medium-4">
                                  <p className="input-wrapper">
                                    <label htmlFor="middleName">
                                      Middle Name
                                    </label>
                                    <input
                                      type="text"
                                      id="middleName"
                                      name="middleName"
                                      value={membership.personBasic.middleName}
                                      onChange={_setGeneralInfo}
                                      autoComplete="additional-name"
                                      maxLength="50"
                                      disabled={membership.personIsComplete}
                                    />
                                  </p>
                                </div>
                                <div className="cell medium-4">
                                  <p
                                    className="input-wrapper"
                                    style={{ marginBottom: "1.3rem" }}
                                  >
                                    <label
                                      htmlFor="lastName"
                                      className={_setErrorClass(
                                        "lastName",
                                        true
                                      )}
                                    >
                                      Last Name *
                                    </label>
                                    <input
                                      type="text"
                                      id="lastName"
                                      name="lastName"
                                      aria-required="true"
                                      value={membership.personBasic.lastName}
                                      autoComplete="family-name"
                                      className={_setErrorClass(
                                        "lastName",
                                        false
                                      )}
                                      onChange={_setGeneralInfo}
                                      maxLength="50"
                                      disabled={membership.personIsComplete}
                                    />
                                  </p>
                                  {membership.errorList.includes(
                                    "lastName"
                                  ) && (
                                    <div
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "red",
                                        fontWeight: "bold",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      Last Name is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                              <hr />
                              <p className="input-wrapper">
                                <label
                                  htmlFor="email"
                                  className={_setErrorClass("email", true)}
                                >
                                  Email Address *{" "}
                                  <span
                                    className={
                                      "helper " + _setErrorClass("email", true)
                                    }
                                  >
                                    (Your email address will be your account
                                    login)
                                  </span>
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  aria-required="true"
                                  value={membership.personBasic.email}
                                  className={_setErrorClass("email", false)}
                                  onChange={_setGeneralInfo}
                                  disabled={true}
                                  autoComplete="off"
                                />
                                {membership.errorList.includes(
                                  "emailInvalid"
                                ) && (
                                  <div className="error-class-email">
                                    Please enter a valid email address
                                  </div>
                                )}
                              </p>
                              {!membership.personBasic.isInSystem && (
                                <p className="input-wrapper">
                                  <label
                                    htmlFor="emailConfirm"
                                    className={_setErrorClass(
                                      "emailConfirm",
                                      true
                                    )}
                                  >
                                    Confirm Email Address *{" "}
                                  </label>
                                  <input
                                    type="email"
                                    id="emailConfirm"
                                    name="emailConfirm"
                                    aria-required="true"
                                    value={membership.personBasic.emailConfirm}
                                    className={_setErrorClass(
                                      "emailConfirm",
                                      false
                                    )}
                                    onChange={_setGeneralInfo}
                                    autoComplete="off"
                                  />
                                  {membership.errorList.includes(
                                    "emailConfirm"
                                  ) && (
                                    <div className="error-class-email">
                                      Email addresses do not match.{" "}
                                      <a onClick={() => history.push("/start")}>
                                        Do you need to change your email
                                        address?
                                      </a>
                                    </div>
                                  )}
                                </p>
                              )}
                            </div>
                            <Address
                              handleErrorClass={_setErrorClass}
                              handleRemoveError={_removeErrorClass}
                              countries={countries}
                              history={history}
                            />
                            <Phone
                              handleErrorClass={_setErrorClass}
                              handleRemoveError={_removeErrorClass}
                              countries={countries}
                            />
                            <div className="box-controls">
                              <p>{duesText}</p>

                              {membership.errorList.length > 0 && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "1.8rem",
                                    fontWeight: "bold",
                                    marginBottom: "20px",
                                  }}
                                >
                                  * Please ensure that all required fields have
                                  been completed properly
                                </div>
                              )}

                              <button type="submit" className="button">
                                Continue
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactInfo;
