import axios from "axios";
//import * as Sentry from "@sentry/react";
import { apiUrl } from "../data/config";
import { handleError } from "../utils/functions";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export function createWebUser(webUser) {
  return axios
    .post(apiUrl + `person/createwebuser`, webUser, config)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error posting web user");
    });
}

export function getPersonById(token) {
  return axios
    .get(apiUrl + `person/getpersonbyid`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting person by id");
    });
}

export function getPersonByEmail(email) {
  return axios
    .get(apiUrl + `person/getpersonbyemail/${email}`)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting person by email");
    });
}

export function getPersonDetailByEmail(email) {
  return axios
    .get(apiUrl + `person/getpersondetailbyemail/${email}`)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error getting person detail by email");
    });
}

export function submitPerson(submitPerson) {
  return axios
    .post(apiUrl + `person/submitperson`, submitPerson)
    .then((response) => response.data)
    .catch((error) => {
      return handleError(error, "Error posting person");
    });
}
