export const membership = {
  accessToken: "",
  address: {
    businessAddressId: -1,
    homeAddressId: -1,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressType: "",
    city: "",
    companyName: "",
    addressID: -1,
    stateProvince: "",
    postalCode: "",
    country: "United States",
    countryID: 222,
    county: "",
    mailExclude: false,
  },
  autoRenew: "",
  autoRenewConfirm: false,
  autoRenewCurrent: false,
  campaign: {
    id: 0,
    code: "",
    discountAmount: 0,
    isValid: false,
    message: "",
  },
  communities: [],
  countries: [],
  currentCommunities: [],
  demographics: {
    americanIndianAlaskaNativeRace: false,
    asianRace: false,
    birthMonth: "1",
    birthYear: "",
    blackAfricanAmericanRace: false,
    gender: "unknown",
    ethnicity: "",
    nativeHawaiianRace: false,
    npi: "",
    npiAttest: false,
    race: [],
    undisclosedRace: false,
    whiteRace: false,
  },
  errorList: [],
  fellowsProduct: {
    id: 0,
    name: "",
    price: 0,
  },
  international: false,
  isCareerStarter: "",
  isLicensedNpStudent: "",
  loginUrl: "",
  memberType: {
    id: 0,
    displayName: "",
    name: "",
    price: 0,
    disabledForUser: false,
    eligibleForAutoRenewal: false,
    isInternational: false,
  },
  memberTypes: [],
  opportunities: {
    currentNetworkForResearch: false,
    currentNpFinder: false,
    networkForResearch: "",
    npFinder: "",
  },
  orderId: 0,
  orderStatus: "incomplete",
  pacDonation: {
    amount: "",
    recognize: true,
  },
  personBasic: {
    id: -1,
    firstName: "",
    middleName: "",
    lastName: "",
    disabled: false,
    email: "",
    emailConfirm: "",
    emailVerified: false,
    accountVerified: false,
    isMember: false,
    isFellow: false,
    isInSystem: false,
    webUserId: -1,
  },
  personIsComplete: false,
  phone: {
    phoneId: -1,
    phoneType: "",
    countryCode: "",
    areaCode: "",
    lineNumber: "",
    phoneNumber: "",
    textAllow: false,
  },
  schools: [],
  selectedCommunities: [],
  stagedCommunities: [],
  states: [],
  student: {
    undergradAttested: false,
    schoolName: "",
  },
  user: {
    id: 0,
    firstName: "",
    lastName: "",
  },
  veteranStatus: "",
};
