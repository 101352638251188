import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMembership } from "../context/membershipContext";
import Modal from "react-modal";
import * as Sentry from "@sentry/react";

import { duesText } from "../data/config";
import * as _api from "../api/personApi";
import {
  getAddressErrors,
  getPasswordErrors,
  getPhoneErrors,
  getPersonBasicErrors,
  getNpiErrors,
} from "../services/validationService";
import { isNavigationValid } from "../utils/functions";
import { modalStyles } from "../styles/modalStyles";

import Demographics from "../components/Demographics";
import NpiModal from "../components/modals/NpiModal";
import ScrollToTop from "../components/ui/ScrollToTop";
import Spinner from "../components/ui/Spinner";
import TopNav from "../components/ui/TopNav";
import Header from "../components/ui/Header";

const Account = (prop) => {
  const { membership, dispatch } = useMembership();
  const history = useHistory();
  const { pathname } = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  const [npiModalIsOpen, setNpiModalIsOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  useEffect(() => {
    if (!isNavigationValid(prop, pathname)) {
      history.push("/error");
    }

    if (membership.memberType.isInternational) {
      const resetDemographics = {
        americanIndianAlaskaNativeRace: false,
        asianRace: false,
        birthMonth: "1",
        birthYear: "",
        blackAfricanAmericanRace: false,
        gender: "unknown",
        ethnicity: "",
        nativeHawaiianRace: false,
        npi: "", //membership.demographics.npi,
        npiAttest: true, // membership.demographics.npiAttest,
        race: [],
        undisclosedRace: false,
        whiteRace: false,
      };

      dispatch({
        type: "updateDemographics",
        demographics: resetDemographics,
      });
    }
  }, []);

  //Navigate either forward to payment, or backward to opportunities
  //Save account info to session storage before navigating
  function _navigateToStep(step) {
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    history.push(step, { from: pathname, propState: "" });
  }

  //Function to save user input into app storage, slightly different from what is done on other pages
  //in that passwords are never saved to app storage, but instead held locally on the page. If the user navs
  //off this page without submitting their data, password will not be saved and the password fields will be blank
  //when the user navs back to this page. NPI is also validated to ensure numerical values only
  function _setAccountData(e) {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    let demographics = membership.demographics;

    switch (name) {
      case "npi":
        demographics.npi = value;
        demographics.npiAttest = false;
        if (value !== "" && (isNaN(value) || isNaN(parseFloat(value)))) {
          const errors = membership.errorList;
          errors.push("npi");
          dispatch({ type: "updateErrorList", errorList: errors });
        } else {
          _removeErrorClass(name);
        }
        break;

      case "npiAttest":
        demographics.npiAttest = checked;
        if (checked) {
          demographics.npi = "";
          _removeErrorClass("npi");
        }
        break;

      case "password":
        setPassword(value);
        _removeErrorClass(name);
        break;

      case "passwordConfirm":
        setPasswordConfirm(value);
        _removeErrorClass(name);
        break;

      default:
        break;
    }

    dispatch({
      type: "updateDemographics",
      demographics,
    });
  }

  //Stock function to remove field from error list
  function _removeErrorClass(name) {
    let errors = membership.errorList;
    let newErrors = [];

    if (name === "password" || name === "passwordConfirm") {
      newErrors = errors.filter(
        (e) => e !== "password" && e !== "passwordConfirm"
      );
    } else {
      newErrors = errors.filter((e) => e !== name);
    }

    dispatch({
      type: "updateErrorList",
      errorList: newErrors,
    });
  }

  //Stock function to change formatting of form field based on whether it is in the error list or not
  //Can this be centralized?
  function _setErrorClass(fieldName, isLabel) {
    if (membership.errorList.length > 0) {
      if (membership.errorList.includes(fieldName)) {
        return isLabel ? "error-class-label" : "error-class";
      }
      return "";
    }
  }

  //Used in validating password
  function _checkUpperCase() {
    return password.match(new RegExp("^(?=.*[A-Z].*)"));
  }

  function _checkLowerCase() {
    return password.match(new RegExp("^(?=.*[a-z].*)"));
  }

  //Used in validating password
  function _checkNumeric() {
    let val = false;
    password.split("").forEach((c) => {
      if (typeof c != "string") return false;
      if (!isNaN(c) && !isNaN(parseFloat(c))) {
        val = true;
      }
    });
    return val;
  }

  async function _submitPerson(e) {
    if (e) {
      e.preventDefault();
    }

    setIsSearching(true);
    sessionStorage.setItem("session_membership", JSON.stringify(membership));
    //Validate submission one last time, just in case; submit here means to post the information to the api
    //and create a new Person in Aptify, so do a extra check on address, phone, etc.
    let addressErrors = getAddressErrors(
      membership.address,
      membership.countries,
      membership.states
    );
    let personBasicErrors = getPersonBasicErrors(membership.personBasic);
    let phoneErrors = getPhoneErrors(membership.phone);
    let npiErrors = getNpiErrors(
      membership.demographics.npi,
      membership.demographics.npiAttest
    );

    let passwordErrors = [];
    if (
      !membership.personIsComplete &&
      !membership.personBasic.accountVerified
    ) {
      passwordErrors = getPasswordErrors(password, passwordConfirm);
    }
    const errors = addressErrors.concat(
      personBasicErrors,
      phoneErrors,
      npiErrors,
      passwordErrors
    );

    dispatch({ type: "updateErrorList", errorList: errors });
    //If there are contact info errors, send the user back to the contact-info page
    if (
      personBasicErrors.length > 0 ||
      addressErrors.length > 0 ||
      phoneErrors.length > 0
    ) {
      history.push("/contact-info", { from: pathname, propState: "" });
      return false;
    }

    //If there are membership errors, send the user back to the member-type page
    if (membership.memberType.id === 0) {
      history.push("/member-type", { from: pathname, propState: "" });
      return false;
    }

    //If there are other errors (npi), return false and show error info
    if (errors.length > 0) {
      setIsSearching(false);
      return false;
    }

    //If there are no errors, send the info on to the api
    if (errors.length === 0) {
      setIsSearching(true);

      const submitPerson = {
        person: {
          id: membership.personBasic.id,
          firstName: membership.personBasic.firstName,
          middleName: membership.personBasic.middleName,
          lastName: membership.personBasic.lastName,
          emailAddress: membership.personBasic.email,
          veteranStatus: membership.veteranStatus,
          networkForResearch:
            membership.opportunities.networkForResearch === "true",
          schoolName: membership.student.schoolName,
          undergradAttested: membership.student.undergradAttested,
        },
        address: membership.address,
        phone: membership.phone,
        demographics: membership.demographics,
        password:
          !membership.personIsComplete &&
          !membership.personBasic.accountVerified
            ? password
            : "xxxxxxxx",
      };

      let result = await _api.submitPerson(submitPerson);
      if (result === null || result === undefined) {
        history.push("/error");
      }

      //If create Person was successful, create Web User
      if (result.id > 0) {
        let personBasic = membership.personBasic;
        personBasic.id = result.id;
        personBasic.emailVerified = true;
        personBasic.accountVerified = true;
        dispatch({ type: "updatePersonBasicData", personBasic });
        dispatch({
          type: "updatePersonIsComplete",
          personIsComplete: true,
        });

        if (personBasic.isMember && !membership.autoRenewCurrent) {
          _navigateToStep("/pac");
        } else {
          _navigateToStep("/review");
        }
      } else {
        Sentry.captureMessage(
          `Submit Person failed for ${submitPerson.person.firstName} ${submitPerson.person.lastName}`
        );
        _navigateToStep("/error");
      }
      //#endregion
    }
    //#endregion
  }

  function _openModal(name) {
    setNpiModalIsOpen(true);
  }

  function _closeModal() {
    setNpiModalIsOpen(false);
  }

  return (
    <>
      <ScrollToTop />
      <Header isMember={membership.personBasic.isMember} />
      <div
        className="grid-container join-content"
        style={{ paddingTop: "10px" }}
      >
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <TopNav
                  currentStep="account"
                  backwardStepDisabled={false}
                  forwardStepDisabled={true}
                  handleNavigate={_navigateToStep}
                />
                <div className="box-content">
                  <div className="text-center">
                    <div className="box-content-intro">
                      <h2>
                        {membership.personBasic.id > 0 ? (
                          <span>Let's verify your account information.</span>
                        ) : (
                          <span>Now let's create your account</span>
                        )}{" "}
                      </h2>
                      <span className="helper">
                        * Indicates a required field.
                      </span>
                    </div>
                  </div>

                  {isSearching ? (
                    <Spinner />
                  ) : (
                    <>
                      <form
                        onSubmit={(e) => {
                          _submitPerson(e);
                        }}
                      >
                        {!membership.personBasic.accountVerified && (
                          <>
                            <div className="box-section">
                              <h3 className="box-section-heading">Password</h3>
                              <div className="grid-x grid-margin-x align-middle">
                                <div className="cell medium-8 large-7">
                                  <p className="input-wrapper">
                                    <label
                                      htmlFor="password"
                                      className={_setErrorClass(
                                        "password",
                                        true
                                      )}
                                    >
                                      Password *
                                    </label>
                                    <input
                                      type="password"
                                      id="password"
                                      name="password"
                                      className={_setErrorClass(
                                        "password",
                                        false
                                      )}
                                      onChange={_setAccountData}
                                      value={password}
                                      autoComplete="new-password"
                                      disabled={false}
                                    />
                                  </p>
                                  <p className="input-wrapper">
                                    <label
                                      htmlFor="password_confirm"
                                      className={_setErrorClass(
                                        "passwordConfirm",
                                        true
                                      )}
                                    >
                                      Re-enter Password *
                                    </label>
                                    <input
                                      type="password"
                                      id="passwordConfirm"
                                      name="passwordConfirm"
                                      className={_setErrorClass(
                                        "passwordConfirm",
                                        false
                                      )}
                                      onChange={_setAccountData}
                                      value={passwordConfirm}
                                      autoComplete="new-password"
                                      disabled={false}
                                    />
                                  </p>
                                  {membership.errorList.includes(
                                    "password"
                                  ) && (
                                    <p
                                      className="error-class-label"
                                      style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Please make sure passwords match and meet
                                      all requirements
                                    </p>
                                  )}
                                </div>
                                <div className="cell medium-4 large-5">
                                  <h4 className="iconList-heading">
                                    Passwords must:
                                  </h4>
                                  <ul className="iconList">
                                    {password.length < 8 ? (
                                      <li className="iconList-unchecked">
                                        <span className="iconList-icon icon-close"></span>
                                        Be at least 8 characters in length
                                      </li>
                                    ) : (
                                      <li className="iconList-checked">
                                        <span className="iconList-icon icon-check"></span>
                                        Be at least 8 characters in length
                                      </li>
                                    )}
                                    {_checkUpperCase() ? (
                                      <li className="iconList-checked">
                                        <span className="iconList-icon icon-check"></span>
                                        Contain 1 uppercase letter
                                      </li>
                                    ) : (
                                      <li className="iconList-unchecked">
                                        <span className="iconList-icon icon-close"></span>
                                        Contain 1 uppercase letter
                                      </li>
                                    )}
                                    {_checkLowerCase() ? (
                                      <li className="iconList-checked">
                                        <span className="iconList-icon icon-check"></span>
                                        Contain 1 lowercase letter
                                      </li>
                                    ) : (
                                      <li className="iconList-unchecked">
                                        <span className="iconList-icon icon-close"></span>
                                        Contain 1 lowercase letter
                                      </li>
                                    )}
                                    {_checkNumeric() ? (
                                      <li className="iconList-checked">
                                        <span className="iconList-icon icon-check"></span>
                                        Contain 1 numeral between 0-9
                                      </li>
                                    ) : (
                                      <li className="iconList-unchecked">
                                        <span className="iconList-icon icon-close"></span>
                                        Contain 1 numeral between 0-9
                                      </li>
                                    )}
                                    {password === passwordConfirm &&
                                    password.length > 0 ? (
                                      <li className="iconList-checked">
                                        <span className="iconList-icon icon-check"></span>
                                        Match
                                      </li>
                                    ) : (
                                      <li className="iconList-unchecked">
                                        <span className="iconList-icon icon-close"></span>
                                        Match
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        )}{" "}
                        {!membership.memberType.isInternational && (
                          <div className="box-section">
                            <h3 className="box-section-heading">NPI Number </h3>
                            <div className="grid-x grid-margin-x align-middle">
                              <div className="cell medium-8 large-7">
                                <p className="input-wrapper">
                                  <label
                                    htmlFor="npi"
                                    className={_setErrorClass("npi", true)}
                                  >
                                    NPI Number
                                  </label>
                                  <input
                                    type="text"
                                    id="npi"
                                    name="npi"
                                    maxLength="10"
                                    className={_setErrorClass("npi", false)}
                                    value={membership.demographics.npi}
                                    onChange={_setAccountData}
                                    disabled={false}
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="grid-x grid-margin-x align-middle">
                              <div className="cell medium-8 large-7">
                                {" "}
                                <p className="input-wrapper">
                                  <a
                                    href="https://npiregistry.cms.hhs.gov/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ fontSize: "1.4rem" }}
                                  >
                                    Look up your NPI number.
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="grid-x grid-margin-x align-middle">
                              <div className="cell medium-8 large-7">
                                <input
                                  type="checkbox"
                                  id="npiAttest"
                                  name="npiAttest"
                                  maxLength="10"
                                  className={_setErrorClass("npi", false)}
                                  checked={membership.demographics.npiAttest}
                                  onChange={_setAccountData}
                                  disabled={false}
                                />
                                <label
                                  htmlFor="npiAttest"
                                  className={_setErrorClass("npi", true)}
                                >
                                  I attest I do not have an NPI Number.
                                </label>

                                <p>
                                  <button
                                    onClick={() => _openModal()}
                                    className="modal-link"
                                    type="button"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    Tell me more.
                                  </button>
                                </p>

                                {membership.errorList.includes("npi") && (
                                  <div
                                    className="error-class-email"
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      marginTop: "2rem",
                                    }}
                                  >
                                    NPI must be entered as a ten-digit number OR
                                    you must attest that you do not have an NPI
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {!membership.memberType.isInternational && (
                          <Demographics />
                        )}
                        <div className="box-controls">
                          <p>{duesText}</p>

                          {membership.errorList.length > 0 && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "1.8rem",
                                fontWeight: "bold",
                                marginBottom: "20px",
                              }}
                            >
                              * Please ensure that all required fields have been
                              completed properly
                            </div>
                          )}

                          <button className="button" type="submit">
                            Continue
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={npiModalIsOpen}
        onRequestClose={() => _closeModal}
        style={modalStyles}
        contentLabel="NPI Number"
      >
        <NpiModal closeModal={_closeModal} />
      </Modal>
    </>
  );
};

export default Account;
