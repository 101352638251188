import React from "react";

const Header = ({ isMember }) => {
  return (
    <div className="join-intro" style={{ paddingBottom: "0px" }}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-7 text-center">
            <h1 id="member-header">
              {isMember
                ? "Renew Your AANP Membership"
                : "Become an AANP Member"}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
