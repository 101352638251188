export const modalStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "40%",
    bottom: "auto",
    marginRight: "-50%",
    padding: "40px",
    maxWidth: "calc(100vw - 2rem)",
    maxHeight: "calc(100vh - 2rem)",
    transform: "translate(-50%, -50%)",
    zIndex: 9900,
    overflowY: "auto",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 89, 153, 0.9)",
    zIndex: 9000,
  },
};
