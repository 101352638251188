import React, { useState } from "react";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useMembership } from "../context/membershipContext";
import { modalStyles } from "../styles/modalStyles";
import TextModal from "./modals/TextModal";

const Phone = ({ handleErrorClass, handleRemoveError, countries }) => {
  const { membership, dispatch } = useMembership();
  const [autoFillUsed, setAutoFillUsed] = useState(false);
  const [textModalIsOpen, setTextModalIsOpen] = useState(false);
  const countryCodes = countries.map((c) => c.isoCode.toLowerCase());

  function _setPhoneData(e) {
    const name = e.target.name;
    const value = e.target.value;

    let updatedMembership = membership;
    switch (name) {
      case "phoneType":
        updatedMembership.phone.phoneType = value;
        break;

      case "textAllow":
        updatedMembership.phone.textAllow = e.target.checked;
        break;

      default:
        break;
    }

    handleRemoveError(name);
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  //The mask works a little differently; on change it only sends through the event value of the dropdown, not the
  //entire event object; set app storage value here
  function _setPhoneNumber(value, country, e, formattedValue) {
    let countryCode = 0;

    //const autoFill = document.querySelector("input:-webkit-autofill");

    //(1) Is autofill being used
    const usingAutoFill =
      document.querySelector("input:-webkit-autofill") !== null;
    if (usingAutoFill && !autoFillUsed && value.length === 10) {
      countryCode = 1;
      setAutoFillUsed(true);
    } else {
      countryCode = country.dialCode;
    }

    if (usingAutoFill && value === null) {
      setAutoFillUsed(false);
    }

    //(1) By default, set the country code to the country that is sent through onChange
    //(2) By default, remove the country code from the value that is sent through onChange
    let phoneWithoutCountryCode = value.substring(countryCode.length);
    let areaCode = "";
    let lineNumber = "";

    if (phoneWithoutCountryCode.length <= 3) {
      areaCode = phoneWithoutCountryCode;
    } else {
      areaCode = phoneWithoutCountryCode.substring(0, 3);
      lineNumber = phoneWithoutCountryCode.substring(3);
    }

    //let phone = membership.phone;
    let updatedMembership = membership;
    updatedMembership.phone.countryCode = countryCode;
    updatedMembership.phone.areaCode = areaCode.trim();
    updatedMembership.phone.lineNumber = lineNumber.trim();
    updatedMembership.phone.phoneNumber =
      countryCode.toString().trim() +
      areaCode.toString().trim() +
      lineNumber.toString().trim();

    handleRemoveError("phoneNumber");
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  //Stock navigation functions to open and close the modal (Tell me more modal about text communications)
  function _closeTextModal() {
    setTextModalIsOpen(false);
  }

  function _openTextModal(e) {
    e.preventDefault();
    setTextModalIsOpen(true);
  }

  return (
    <>
      <div className="box-section">
        <div className="grid-x">
          <div className="cell large-8 box-section-content">
            <h3 className="box-section-heading">Add a Phone Number</h3>
            <fieldset className="radioFancy-wrapper">
              <legend>
                <span className={handleErrorClass("phoneType", true)}>
                  This is my preferred phone number: *
                </span>
              </legend>

              <div className="radioFancy">
                <input
                  type="radio"
                  id="phoneType_cell"
                  className="radioFancy-input"
                  value="cell phone"
                  name="phoneType"
                  data-show="phoneType"
                  checked={membership.phone.phoneType
                    .toLowerCase()
                    .includes("cell")}
                  onClick={_setPhoneData}
                  disabled={membership.personIsComplete}
                />
                <label htmlFor="phoneType_cell" className="radioFancy-label">
                  Cell
                </label>
              </div>
              <div className="radioFancy">
                <input
                  type="radio"
                  id="phoneType_business"
                  className="radioFancy-input"
                  value="business phone"
                  name="phoneType"
                  data-show="phoneType"
                  checked={membership.phone.phoneType
                    .toLowerCase()
                    .includes("business")}
                  onClick={_setPhoneData}
                  disabled={membership.personIsComplete}
                />
                <label
                  htmlFor="phoneType_business"
                  className="radioFancy-label"
                >
                  Work
                </label>
              </div>
              <div className="radioFancy">
                <input
                  type="radio"
                  id="phoneType_home"
                  className="radioFancy-input"
                  value="home phone"
                  name="phoneType"
                  data-show="phoneType"
                  checked={membership.phone.phoneType
                    .toLowerCase()
                    .includes("home")}
                  onClick={_setPhoneData}
                  disabled={membership.personIsComplete}
                />
                <label htmlFor="phoneType_home" className="radioFancy-label">
                  Home
                </label>
              </div>
              {membership.errorList.includes("phoneType") && (
                <div
                  style={{
                    fontSize: "1.3rem",
                    color: "red",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  Phone Type is required.
                </div>
              )}
            </fieldset>

            <p className="input-wrapper">
              <label
                htmlFor="phone"
                className={handleErrorClass("phoneNumber", true)}
              >
                Phone Number *
              </label>
              <PhoneInput
                inputProps={{
                  id: "phone",
                  name: "phoneNumber",
                  autoComplete: "tel",
                }}
                inputStyle={{
                  fontSize: "1.8rem",
                  height: "5.5rem",
                  width: "100%",
                }}
                country={"us"}
                preferredCountries={["us"]}
                onlyCountries={countryCodes}
                inputClass={handleErrorClass("phoneNumber", false)}
                value={membership.phone.phoneNumber}
                onChange={_setPhoneNumber}
                disabled={membership.personIsComplete}
              />
              {membership.errorList.includes("phoneNumber") && (
                <div
                  style={{
                    fontSize: "1.3rem",
                    color: "red",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    marginTop: "1.3rem",
                  }}
                >
                  Phone Number is required.
                </div>
              )}
            </p>

            {membership.phone.phoneType.toLowerCase().includes("cell") && (
              <div className="input-wrapper m-b-0" data-phoneType="cell">
                <input
                  type="checkbox"
                  id="textAllow"
                  name="textAllow"
                  checked={membership.phone.textAllow}
                  onChange={_setPhoneData}
                  disabled={membership.personIsComplete}
                />
                <label htmlFor="textAllow">
                  I would like to receive communications by text. <br />
                  <button onClick={_openTextModal} className="modal-link">
                    Tell me more.
                  </button>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={textModalIsOpen}
        onRequestClose={_closeTextModal}
        style={modalStyles}
        contentLabel="Auto Renew"
      >
        <TextModal closeModal={_closeTextModal} />
      </Modal>
    </>
  );
};

export default Phone;
