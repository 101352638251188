import React from "react";
import { welcomeText } from "../data/config";
import { useMembership } from "../context/membershipContext";

const RecentOrder = () => {
  const { membership } = useMembership();

  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const marginTop = {
    marginTop: isMobile ? "20px" : "",
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="grid-container" id={isMobile ? "join-banner" : ""}>
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-7 text-center">
              <div style={{ paddingBottom: "4rem" }}>{welcomeText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container join-content">
        <div className="grid-x grid-margin-x align-center">
          <div className="cell large-10 xlarge-9">
            <div className="box">
              <div className="page is-active is-loaded" style={marginTop}>
                <div className="box-header">
                  <legend>Unable to complete your order</legend>
                </div>
                <div className="box-content">
                  <fieldset className="box-section">
                    <h3 className="box-section-heading">
                      You have recently completed a membership order
                    </h3>
                    <p className="input-wrapper">
                      You have already placed a successful order within the past
                      two hours.
                      <p />
                      You can{" "}
                      <a
                        href={
                          process.env.INVOICE_URL +
                          membership.orderId +
                          "&td=history"
                        }
                      >
                        view your invoice
                      </a>{" "}
                      or contact the Membership department at{" "}
                      <a href="mailto:membership@aanp.org">
                        membership@aanp.org
                      </a>{" "}
                      for any questions.
                      <p />
                    </p>
                    <div className="text-center" style={{ marginTop: "20px" }}>
                      <a
                        href={
                          process.env.INVOICE_URL +
                          membership.orderId +
                          "&td=history"
                        }
                        class="button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Invoice
                      </a>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RecentOrder;
