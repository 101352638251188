import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPersonById } from "../api/personApi";
import { useMembership } from "../context/membershipContext";
import userManager from "../services/userService";
import Spinner from "./ui/Spinner";
import * as Sentry from "@sentry/react";
import TopNav from "./ui/TopNav";
import Header from "./ui/Header";

const SignIn = () => {
  const { membership, dispatch } = useMembership();
  const { pathname } = useLocation();
  const history = useHistory();
  //console.log("signin membership", membership);

  useEffect(() => {
    async function _signInUser() {
      try {
        const user = await userManager.signinRedirectCallback();
        if (typeof user === "undefined") {
          Sentry.captureMessage(`No user found`);
          history.push("/error");
        }

        if (isNaN(user.profile.sub)) {
          Sentry.captureMessage(
            `Unable to login user ${membership.personBasic.email}`
          );
          history.push("/error");
        }

        if (typeof user.access_token !== "undefined") {
          getPersonById(user.access_token)
            .then((response) => {
              //Capture person data from Aptify; load into app storage
              if (response.success) {
                let updatedMembership = membership;
                if (response.data.isOnAutoRenew === "Yes") {
                  history.push("/auto-renew");
                } else {
                  updatedMembership.accessToken = user.access_token;

                  updatedMembership.personBasic = {
                    id: response.data.personID,
                    firstName: response.data.firstName,
                    middleName: response.data.middleName,
                    lastName: response.data.lastName,
                    email: response.data.email1,
                    emailConfirm: response.data.email1,
                    emailVerified: true,
                    accountVerified: true,
                    memberType: response.data.memberType,
                    isMember: response.data.isMember === "Yes",
                    isFellow: response.data.isFellow,
                    isInSystem: true,
                  };

                  //load address data from Aptify into app storage
                  if (response.data.addressType !== null) {
                    updatedMembership.address = {
                      businessAddressId: response.data.businessAddressID,
                      homeAddressId: response.data.homeAddressID,
                      addressType:
                        response.data.addressType
                          .toLowerCase()
                          .indexOf("home") >= 0
                          ? "home"
                          : "work",
                      addressLine1: response.data.line1,
                      addressLine2: response.data.line2,
                      addressLine3: response.data.line3,
                      city: response.data.city,
                      stateProvince: response.data.stateProvince,
                      postalCode: response.data.postalCode,
                      country: response.data.country,
                      countryID: parseInt(response.data.countryCodeID, 10),
                      companyName: response.data.companyName,
                    };

                    if (response.data.preferredPhoneType !== null) {
                      const countryCode =
                        response.data.countryCode == null ||
                        response.data.countryCode.trim() === ""
                          ? "1"
                          : response.data.countryCode;

                      const phoneId =
                        response.data.preferredPhoneId > 0
                          ? response.data.preferredPhoneId
                          : -1;

                      updatedMembership.phone = {
                        phoneId: phoneId,
                        phoneType:
                          response.data.preferredPhoneType.toLowerCase(),
                        areaCode: response.data.preferredPhone.substring(1, 4),
                        lineNumber: response.data.preferredPhone
                          .substring(5)
                          .trim(),
                        phoneNumber:
                          countryCode + " " + response.data.preferredPhone,
                      };
                    }

                    if (response.data.currentCommunities.length > 0) {
                      updatedMembership.selectedCommunities =
                        response.data.currentCommunities;
                    }

                    //Load available demographic data from Aptify into app storage
                    updatedMembership.demographics = {
                      americanIndianAlaskaNativeRace:
                        response.data.americanIndianAlaskaNativeRace,
                      asianRace: response.data.asianRace,
                      birthMonth:
                        response.data.birthday.search("1900") === -1
                          ? response.data.birthday.slice(5, 7).toString()
                          : "",
                      birthYear:
                        response.data.birthday.search("1900") === -1
                          ? response.data.birthday.slice(0, 4).toString()
                          : "",
                      blackAfricanAmericanRace:
                        response.data.blackAfricanAmericanRace,
                      ethnicity: response.data.ethnicity,
                      nativeHawaiianRace: response.data.nativeHawaiianRace,
                      npi: response.data.npi,
                      gender: _getGenderText(response.data.gender),
                      whiteRace: response.data.whiteRace,
                    };

                    updatedMembership.opportunities = {
                      currentNetworkForResearch:
                        response.data.networkForResearch,
                      currentNpFinder: response.data.npFinder,
                      networkForResearch:
                        response.data.networkForResearch === true ? "true" : "",
                      npFinder: response.data.npFinder === true ? "true" : "",
                    };

                    updatedMembership.student = {
                      schoolName: response.data.schoolName,
                    };

                    dispatch({
                      type: "updateMembership",
                      updatedMembership,
                    });
                  } else {
                    Sentry.captureMessage(
                      `Unable to retrieve person data for user ${membership.personBasic.email}`
                    );
                    history.push("/error");
                  }
                  sessionStorage.setItem(
                    "session_membership",
                    JSON.stringify(membership)
                  );

                  history.push("/contact-info", {
                    from: pathname,
                    propState: "",
                  });
                }
              }
            })
            .catch((error) => {
              Sentry.captureMessage(
                `Unable to login user ${membership.personBasic.email}`
              );
              history.push("/error");
            });
        } else {
          Sentry.captureMessage(
            `Invalid token trying to authenticate user ${membership.personBasic.email}`
          );
          history.push("/error");
        }
      } catch (error) {
        history.push("/error");
      }
    }

    //User info has already been retrieved from the database
    if (membership.personBasic.id > 0) {
      history.push("/contact-info", { from: pathname, propState: "" });
    } else {
      _signInUser();
    }
  }, []);

  function _getGenderText(val) {
    switch (val) {
      case 0:
        return "male";

      case 1:
        return "female";

      case 3:
        return "other";

      default:
        return "unknown";
    }
  }

  function _handleNavigate() {
    return false;
  }

  return (
    <>
      <Header isMember={false} />
      <div className="grid-container join-content">
        <div
          className="grid-x grid-margin-x align-center"
          style={{ marginBottom: "2.5rem" }}
        >
          <div className="cell large-10 xlarge-9">
            <div className="box" style={{ maxWidth: "100%" }}>
              <div className="page is-active is-loaded">
                <TopNav
                  currentStep={1}
                  backwardStepDisabled={true}
                  forwardStepDisabled={true}
                  handleNavigate={_handleNavigate}
                />
                <div className="box-content">
                  <div className="text-center">
                    <div className="box-section">
                      <Spinner />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
