export const pacDonationProductId = 32;
// export const contactUrl = "https://www..com/contact";
// export const invoiceUrl =
//   "https://my.aanpqa.com/CustomerService/OrderConfirmation.aspx?ID=";
// export const memberBenefitsUrl =
//   "https://www.aanpqa.com/membership/member-benefits/membership-impact-center";
// export const pacUrl =
//   "https://www.aanpqa.com/advocacy/federal/aanp-pac-members";

export const birthYearParams = {
  earliest: 110, //in honor of Loretta Ford
  latest: 16,
};

export const paymentType = {
  INVALID: 0,
  VISA: 24,
  MASTERCARD: 25,
  AMEX: 26,
  DISCOVER: 27,
};

export const welcomeText = `Be a part of the largest professional association for nurse practitioners (NPs) of all specialties. AANP membership is here for you at every stage of your education and profession. 
  A membership gives you the opportunity to receive two digital journals, the latest news on topics that matter, access to a comprehensive library 
  of continuing education (CE) activities for all specialties and experience levels at discounted pricing, advocacy at the state and national level, 
  practice tools and so much more. You belong here.`;

export const duesText = `AANP membership dues are not deductible as a charitable contribution for U.S. federal income tax purposes, 
  but may be deductible as a business expense. AANP estimates that 10% of your dues are not deductible because of AANP's lobbying activities on behalf of members. 
  AANP membership dues are non-refundable.`;

export const intlTransactionText = `Your credit card company may impose additional fees for this transaction if processing internationally. 
Please contact your credit card company directly for more details.`;

export const validNavigations = {
  "/account": ["/opportunities"],
  "/contact-info": ["/account", "/member-type", "/signin", "/start"],
  "/discounts": ["/member-type", "/opportunities"],
  "/member-type": ["/account", "/contact-info", "/discounts", "/opportunities"],
  "/opportunities": ["/account", "/discounts"],
  "/pac": ["/account", "/review"],
  "/review": ["/account", "/pac"],
};

export const apiUrl = process.env.REACT_APP_API_URL;
export const authConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  client_id: process.env.REACT_APP_CLIENT_ID,
  response_mode: process.env.REACT_APP_RESPONSE_MODE,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: false,
};
