import React from "react";
import { useMembership } from "../context/membershipContext";
import { birthYearParams } from "../data/config";

import Modal from "react-modal";
import { modalStyles } from "../styles/modalStyles";
import DateOfBirthModal from "./modals/DateOfBirthModal";

const Demographics = () => {
  const { membership, dispatch } = useMembership();
  const [dateOfBirthModalIsOpen, setDateOfBirthModalIsOpen] =
    React.useState(false);
  const birthYears = () => {
    const currentYear = new Date().getFullYear();
    let yrs = [];
    for (
      var i = currentYear - birthYearParams.earliest;
      i <= currentYear - birthYearParams.latest;
      i++
    ) {
      yrs.push(i);
    }
    return yrs.reverse();
  };

  function _setDemographicData(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedMembership = membership;

    switch (name) {
      case "americanIndianAlaskaNativeRace":
        updatedMembership.demographics.americanIndianAlaskaNativeRace =
          e.target.checked;
        updatedMembership.demographics.undisclosedRace = false;
        break;

      case "asianRace":
        updatedMembership.demographics.asianRace = e.target.checked;
        updatedMembership.demographics.undisclosedRace = false;
        break;

      case "birthYear":
        updatedMembership.demographics.birthYear =
          e.target.selectedOptions[0].text;
        break;

      case "blackAfricanAmericanRace":
        updatedMembership.demographics.blackAfricanAmericanRace =
          e.target.checked;
        updatedMembership.demographics.undisclosedRace = false;
        break;

      case "ethnicity":
        updatedMembership.demographics.ethnicity = value;
        break;

      case "gender":
        updatedMembership.demographics.gender = value;
        break;

      case "nativeHawaiianRace":
        updatedMembership.demographics.nativeHawaiianRace = e.target.checked;
        updatedMembership.demographics.undisclosedRace = false;
        break;

      case "undisclosedRace":
        updatedMembership.demographics.undisclosedRace = e.target.checked;
        updatedMembership.demographics.asianRace = false;
        updatedMembership.demographics.blackAfricanAmericanRace = false;
        updatedMembership.demographics.nativeHawaiianRace = false;
        updatedMembership.demographics.whiteRace = false;
        updatedMembership.demographics.americanIndianAlaskaNativeRace = false;
        break;

      case "whiteRace":
        updatedMembership.demographics.whiteRace = e.target.checked;
        updatedMembership.demographics.undisclosedRace = false;
        break;

      default:
        break;
    }

    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  function _closeDemographicsModal() {
    // const selectedCommunities = [];
    // dispatch({ type: "updateSelectedCommunities", selectedCommunities });
    setDateOfBirthModalIsOpen(false);
  }

  function _openDemographicsModal(e) {
    e.preventDefault();
    setDateOfBirthModalIsOpen(true);
  }

  return (
    <>
      <div class="box-section">
        <h3 class="box-section-heading">Demographics (optional)</h3>
        <div class="grid-x">
          <div class="cell large-8 box-section-content">
            <fieldset class="radioFancy-wrapper">
              <legend>
                Gender (optional){" "}
                <button
                  type="button"
                  class="tooltip-icon"
                  tabindex="-1"
                  title="Obtaining this information would increase our understanding of how to improve the member experience and promote diverse NP voices on a global scale."
                >
                  <span class="show-for-sr">Trigger tooltip</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                  >
                    <defs></defs>
                    <circle
                      class="a"
                      cx="8.5"
                      cy="8.5"
                      r="8.5"
                      style={{ fill: "#3072d7" }}
                    />
                    <text
                      class="b"
                      transform="translate(6 13)"
                      style={{
                        fill: "#fff",
                        fontSize: "12px",
                        fontFamily: "OpenSans-Bold, Open Sans",
                        fontWeight: "700",
                      }}
                    >
                      <tspan x="0" y="0">
                        ?
                      </tspan>
                    </text>
                  </svg>
                </button>
              </legend>

              <div class="radioFancy">
                <input
                  type="radio"
                  id="gender_male"
                  class="radioFancy-input"
                  value="male"
                  name="gender"
                  onClick={_setDemographicData}
                  checked={membership.demographics.gender === "male"}
                  disabled={false}
                />
                <label htmlFor="gender_male" class="radioFancy-label">
                  Male
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="radio"
                  id="gender_female"
                  class="radioFancy-input"
                  value="female"
                  name="gender"
                  onClick={_setDemographicData}
                  checked={membership.demographics.gender === "female"}
                  disabled={false}
                />
                <label htmlFor="gender_female" class="radioFancy-label">
                  Female
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="radio"
                  id="gender_other"
                  class="radioFancy-input"
                  value="other"
                  name="gender"
                  onClick={_setDemographicData}
                  checked={membership.demographics.gender === "other"}
                  disabled={false}
                />
                <label htmlFor="gender_other" class="radioFancy-label">
                  Other (e.g., gender fluid, non-binary)
                </label>
              </div>

              {/* <div class="radioFancy">
                <input
                  type="radio"
                  id="gender_unknown"
                  class="radioFancy-input"
                  value="unknown"
                  name="gender"
                  onClick={_setDemographicData}
                  checked={membership.demographics.gender === "unknown"}
                  disabled={false}
                />
                <label htmlFor="gender_unknown" class="radioFancy-label">
                  Unknown
                </label>
              </div> */}
            </fieldset>
            <fieldset class="radioFancy-wrapper">
              <legend>
                Ethnicity{" "}
                <button
                  type="button"
                  class="tooltip-icon"
                  tabindex="-1"
                  title="Obtaining this information would increase our understanding of how to improve the member experience and promote diverse NP voices on a global scale."
                >
                  <span class="show-for-sr">Trigger tooltip</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                  >
                    <defs></defs>
                    <circle
                      class="a"
                      cx="8.5"
                      cy="8.5"
                      r="8.5"
                      style={{ fill: "#3072d7" }}
                    />
                    <text
                      class="b"
                      transform="translate(6 13)"
                      style={{
                        fill: "#fff",
                        fontSize: "12px",
                        fontFamily: "OpenSans-Bold, Open Sans",
                        fontWeight: "700",
                      }}
                    >
                      <tspan x="0" y="0">
                        ?
                      </tspan>
                    </text>
                  </svg>
                </button>
              </legend>

              <div class="radioFancy">
                <input
                  type="radio"
                  id="ethnicity_hispanic"
                  class="radioFancy-input"
                  value="Hispanic or Latino"
                  name="ethnicity"
                  onClick={_setDemographicData}
                  checked={
                    membership.demographics.ethnicity === "Hispanic or Latino"
                  }
                  disabled={false}
                />
                <label htmlFor="ethnicity_hispanic" class="radioFancy-label">
                  Hispanic or Latino
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="radio"
                  id="ethnicity_notHispanic"
                  class="radioFancy-input"
                  value="Not Hispanic or Latino"
                  name="ethnicity"
                  onClick={_setDemographicData}
                  checked={
                    membership.demographics.ethnicity ===
                    "Not Hispanic or Latino"
                  }
                  disabled={false}
                />
                <label htmlFor="ethnicity_notHispanic" class="radioFancy-label">
                  Not Hispanic or Latino
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="radio"
                  id="ethnicity_undisclosed"
                  class="radioFancy-input"
                  value="undisclosed"
                  name="ethnicity"
                  onClick={_setDemographicData}
                  checked={membership.demographics.ethnicity === "undisclosed"}
                  disabled={false}
                />
                <label htmlFor="ethnicity_undisclosed" class="radioFancy-label">
                  Prefer not to say
                </label>
              </div>
            </fieldset>
            <fieldset class="radioFancy-wrapper">
              <legend>
                Race (Select all that apply){" "}
                <button
                  type="button"
                  class="tooltip-icon"
                  tabindex="-1"
                  title="Obtaining this information would increase our understanding of how to improve the member experience and promote diverse NP voices on a global scale."
                >
                  <span class="show-for-sr">Trigger tooltip</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                  >
                    <defs></defs>
                    <circle
                      class="a"
                      cx="8.5"
                      cy="8.5"
                      r="8.5"
                      style={{ fill: "#3072d7" }}
                    />
                    <text
                      className="b"
                      transform="translate(6 13)"
                      style={{
                        fill: "#fff",
                        fontSize: "12px",
                        fontFamily: "OpenSans-Bold, Open Sans",
                        fontWeight: "700",
                      }}
                    >
                      <tspan x="0" y="0">
                        ?
                      </tspan>
                    </text>
                  </svg>
                </button>
              </legend>

              <div class="radioFancy">
                <input
                  type="checkbox"
                  checked={
                    membership.demographics.americanIndianAlaskaNativeRace
                  }
                  className="radioFancy-input"
                  disabled={false}
                  id="race_americanIndianAlaskaNative"
                  name="americanIndianAlaskaNativeRace"
                  value="true"
                  onChange={_setDemographicData}
                />
                <label
                  htmlFor="race_americanIndianAlaskaNative"
                  class="radioFancy-label"
                >
                  American Indian/Alaska Native
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="checkbox"
                  checked={membership.demographics.asianRace}
                  className="radioFancy-input"
                  disabled={false}
                  id="race_asian"
                  name="asianRace"
                  value="true"
                  onChange={_setDemographicData}
                />
                <label htmlFor="race_asian" class="radioFancy-label">
                  Asian
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="checkbox"
                  checked={membership.demographics.blackAfricanAmericanRace}
                  className="radioFancy-input"
                  disabled={false}
                  id="race_blackAfricanAmericanRace"
                  name="blackAfricanAmericanRace"
                  value="true"
                  onChange={_setDemographicData}
                />
                <label
                  htmlFor="race_blackAfricanAmericanRace"
                  class="radioFancy-label"
                >
                  Black/African American
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="checkbox"
                  checked={membership.demographics.nativeHawaiianRace}
                  className="radioFancy-input"
                  disabled={false}
                  id="race_nativeHawaiianRace"
                  name="nativeHawaiianRace"
                  value="true"
                  onChange={_setDemographicData}
                />
                <label
                  htmlFor="race_nativeHawaiianRace"
                  class="radioFancy-label"
                >
                  Native Hawaiian or Other Pacific Islander
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="checkbox"
                  checked={membership.demographics.whiteRace}
                  className="radioFancy-input"
                  disabled={false}
                  id="race_whiteRace"
                  name="whiteRace"
                  value="true"
                  onChange={_setDemographicData}
                />
                <label htmlFor="race_whiteRace" class="radioFancy-label">
                  White
                </label>
              </div>

              <div class="radioFancy">
                <input
                  type="checkbox"
                  checked={membership.demographics.undisclosedRace}
                  className="radioFancy-input"
                  disabled={false}
                  id="race_undisclosedRace"
                  name="undisclosedRace"
                  value="true"
                  onChange={_setDemographicData}
                />
                <label htmlFor="race_undisclosedRace" class="radioFancy-label">
                  Prefer not to say
                </label>
              </div>
            </fieldset>
            <fieldset>
              <legend>
                Year of Birth{" "}
                <button
                  onClick={_openDemographicsModal}
                  className="modal-link"
                  style={{ marginLeft: "10px", fontSize: "1rem" }}
                >
                  Why does AANP need this?
                </button>
              </legend>
              <div class="grid-x grid-margin-x">
                <div class="cell medium-4 small-7">
                  <div class="input-wrapper">
                    <select
                      id="birthYear"
                      name="birthYear"
                      value={membership.demographics.birthYear}
                      onChange={_setDemographicData}
                      className={
                        membership.errorList.includes("birthdate")
                          ? "error-class"
                          : ""
                      }
                    >
                      <option value="">Select Year</option>
                      {birthYears().map((y) => {
                        return (
                          <option key={y} value={y}>
                            {y}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <Modal
        isOpen={dateOfBirthModalIsOpen}
        onRequestClose={_closeDemographicsModal}
        style={modalStyles}
        contentLabel="Date of Birth"
      >
        <DateOfBirthModal closeModal={_closeDemographicsModal} />
      </Modal>
    </>
  );
};

export default Demographics;
