import React from "react";

const AutoRenewModal = ({ closeModal }) => {
  return (
    <div class="modal-body">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-11">
          <h3>All About Auto-Renew</h3>
        </div>

        <div className="cell small-1">
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
      </div>
      <p>
        AANP will continue to charge your credit card or debit card on a
        recurring basis for the amount of the current membership fees associated
        with your requested membership type, AANP Communities and AANP-PAC
        contribution (as applicable). Current rates are published at{" "}
        <a
          href="https://www.aanp.org/membership"
          target="_blank"
          rel="noreferrer"
        >
          aanp.org/membership
        </a>
        . Your card will be charged upon the expiration of your membership term.
        You may revoke this authorization at any time by calling 512-505-0242 or
        emailing <a href="mailto:membership@aanp.org">membership@aanp.org</a>.
        This authorization will remain in effect until you notify AANP of your
        revocation of this authorization. Auto-Renewal is not available for
        time-limited membership categories such as student membership.
      </p>
    </div>
  );
};

export default AutoRenewModal;
