import React, { useState, useEffect } from "react";

import { useMembership } from "../context/membershipContext";
import { getStateProvinces } from "../api/membershipApi";
import { UsAndTerritoriesId } from "../data/data";

import Spinner from "./ui/Spinner";

const Address = ({
  handleErrorClass,
  handleRemoveError,
  countries,
  history,
}) => {
  const { membership, dispatch } = useMembership();
  const [isSearching, setIsSearching] = useState(false);
  const [stateLabel, setStateLabel] = useState("State");

  useEffect(() => {
    _setStateProvinces(
      membership.address.countryID,
      membership.address.country
    );
  }, []);

  //Stock function to set the address data in app state
  function _setAddressData(e) {
    let name = e.target.name;
    const value = e.target.value;

    let updatedMembership = membership;
    switch (name) {
      case "addressType":
        if (value === "home") {
          updatedMembership.address.companyName = "";
        }
        updatedMembership.address.addressType = value;
        break;

      case "countryID":
        if (membership.address.countryID !== parseInt(value, 10)) {
          _setStateProvinces(value, e.target.selectedOptions[0].text);
        }
        updatedMembership.address.countryID = parseInt(value, 10);
        updatedMembership.address.country = e.target.selectedOptions[0].text;
        break;

      case "mailExclude":
        updatedMembership.address.mailExclude = e.target.checked;
        break;

      default:
        updatedMembership.address[name] = value;
        break;
    }

    handleRemoveError(name);
    dispatch({
      type: "updateMembership",
      updatedMembership,
    });
  }

  function _setStateProvinces(id, name) {
    let states = [];
    //Default values to US
    let searchCountry = name !== "" ? name : "United States";
    if (
      parseInt(id, 10) ===
        UsAndTerritoriesId.find((us) => us.countryName === "United States")
          .countryID ||
      parseInt(id, 10) === 0
    ) {
      setStateLabel("State");
    } else {
      setStateLabel("State / Province");
    }

    if (
      membership.states.length === 0 ||
      !membership.states.some((s) => s.countryID === id)
    ) {
      setIsSearching(true);
      getStateProvinces(searchCountry).then((s) => {
        if (s.success) {
          states = s.list;
          dispatch({
            type: "updateStateProvinces",
            states,
          });
        } else {
          history.push("/error");
        }
      });
      setIsSearching(false);
    }
  }

  return (
    <>
      <div className="box-section">
        <div className="grid-x">
          <div className="cell large-8 box-section-content">
            <h3 className="box-section-heading">Add an Address</h3>
            <fieldset className="radioFancy-wrapper">
              <legend>
                <span className={handleErrorClass("addressType", true)}>
                  This is my preferred mailing address: *
                </span>
              </legend>

              <div className="radioFancy">
                <input
                  type="radio"
                  id="address_home"
                  className="radioFancy-input"
                  name="addressType"
                  value="home"
                  checked={membership.address.addressType === "home"}
                  onClick={_setAddressData}
                  disabled={membership.personIsComplete}
                />
                <label className="radioFancy-label" htmlFor="address_home">
                  Home
                </label>
              </div>

              <div className="radioFancy">
                <input
                  type="radio"
                  id="address_work"
                  name="addressType"
                  className="radioFancy-input"
                  value="work"
                  checked={membership.address.addressType === "work"}
                  onClick={_setAddressData}
                  disabled={membership.personIsComplete}
                />
                <label className="radioFancy-label" htmlFor="address_work">
                  Work
                </label>
              </div>
              {membership.errorList.includes("addressType") && (
                <div
                  style={{
                    fontSize: "1.3rem",
                    color: "red",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  Address Type Is required.
                </div>
              )}
            </fieldset>
            <div className="cell medium-12 small-12">
              <p className="input-wrapper">
                <label
                  htmlFor="country"
                  className={handleErrorClass("countryID", true)}
                >
                  Country *
                </label>
                <select
                  id="country"
                  name="countryID"
                  aria-required="true"
                  className={handleErrorClass("countryID", false)}
                  value={membership.address.countryID}
                  onChange={_setAddressData}
                >
                  <option value="0">Select a Country</option>
                  {countries.map((c) => {
                    return (
                      <option key={c.countryID} value={c.countryID}>
                        {c.countryName}
                      </option>
                    );
                  })}
                </select>
                {membership.errorList.includes("country") && (
                  <div
                    style={{
                      fontSize: "1.3rem",
                      color: "red",
                      fontWeight: "bold",
                      marginBottom: "1rem",
                    }}
                  >
                    Country is required.
                  </div>
                )}
              </p>
            </div>
            {parseInt(membership.address.countryID, 10) !== 0 && (
              <>
                {isSearching ? (
                  <Spinner />
                ) : (
                  <>
                    {membership.address.addressType === "work" && (
                      <p className="input-wrapper">
                        <label
                          htmlFor="companyName"
                          className={handleErrorClass("companyName", true)}
                        >
                          Company Name *
                        </label>
                        <input
                          type="text"
                          id="companyName"
                          name="companyName"
                          aria-required={
                            membership.address.addressType === "work"
                          }
                          className={handleErrorClass("companyName", false)}
                          autoComplete="organization"
                          value={membership.address.companyName}
                          onChange={_setAddressData}
                        />
                        {membership.errorList.includes("companyName") && (
                          <div
                            style={{
                              fontSize: "1.3rem",
                              color: "red",
                              fontWeight: "bold",
                              marginBottom: "1rem",
                            }}
                          >
                            Company Name is required.
                          </div>
                        )}
                      </p>
                    )}
                    <p className="input-wrapper">
                      <label
                        htmlFor="addressLine1"
                        className={handleErrorClass("addressLine1", true)}
                      >
                        Address Line 1 *
                      </label>
                      <input
                        type="text"
                        id="addressLine1"
                        name="addressLine1"
                        aria-required="true"
                        autoComplete="address-line1"
                        className={handleErrorClass("addressLine1", false)}
                        value={membership.address.addressLine1}
                        onChange={_setAddressData}
                        disabled={membership.personIsComplete}
                      />
                      {membership.errorList.includes("addressLine1") && (
                        <div
                          style={{
                            fontSize: "1.3rem",
                            color: "red",
                            fontWeight: "bold",
                            marginBottom: "1rem",
                          }}
                        >
                          Address Line 1 is required.
                        </div>
                      )}
                    </p>
                    <p className="input-wrapper">
                      <label htmlFor="addressLine2">Address Line 2</label>
                      <input
                        type="text"
                        id="addressLine2"
                        name="addressLine2"
                        autoComplete="address-line2"
                        className=""
                        value={membership.address.addressLine2}
                        onChange={_setAddressData}
                        disabled={membership.personIsComplete}
                      />
                    </p>
                    <p className="input-wrapper">
                      <label htmlFor="addressLine3">Address Line 3</label>
                      <input
                        type="text"
                        id="addressLine3"
                        name="addressLine3"
                        autoComplete="address-line3"
                        className=""
                        value={membership.address.addressLine3}
                        onChange={_setAddressData}
                        disabled={membership.personIsComplete}
                      />
                    </p>
                    {membership.states.length === 0 && (
                      <p className="input-wrapper">
                        <label htmlFor="county">Province / Region</label>
                        <input
                          type="text"
                          id="county"
                          name="county"
                          className=""
                          value={membership.address.county}
                          onChange={_setAddressData}
                          disabled={membership.personIsComplete}
                        />
                      </p>
                    )}
                    <p className="input-wrapper">
                      <label
                        htmlFor="city"
                        className={handleErrorClass("city", true)}
                      >
                        City *
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        aria-required="true"
                        autoComplete="address-level2"
                        className={handleErrorClass("city", false)}
                        value={membership.address.city}
                        onChange={_setAddressData}
                        disabled={membership.personIsComplete}
                      />
                      {membership.errorList.includes("city") && (
                        <div
                          style={{
                            fontSize: "1.3rem",
                            color: "red",
                            fontWeight: "bold",
                            marginBottom: "1rem",
                          }}
                        >
                          City is required.
                        </div>
                      )}
                    </p>
                    <div className="grid-x grid-margin-x">
                      {membership.states.length > 0 && (
                        <div className="cell medium-6 small-6">
                          <label
                            htmlFor="state"
                            className={handleErrorClass("stateProvince", true)}
                          >
                            {stateLabel} *
                          </label>
                          <select
                            name="stateProvince"
                            id="stateProvince"
                            aria-required="true"
                            autoComplete="address-level1"
                            className={handleErrorClass("stateProvince", false)}
                            value={membership.address.stateProvince}
                            onChange={_setAddressData}
                            disabled={membership.personIsComplete}
                          >
                            <option value=""></option>
                            {membership.states.map((s) => {
                              return (
                                <option key={s.stateAbb} value={s.stateAbb}>
                                  {s.state}
                                </option>
                              );
                            })}
                          </select>
                          {membership.errorList.includes("stateProvince") && (
                            <div
                              style={{
                                fontSize: "1.3rem",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "1rem",
                              }}
                            >
                              State is required.
                            </div>
                          )}
                        </div>
                      )}
                      <div className="cell medium-6 small-6">
                        <label
                          htmlFor="postalCode"
                          className={handleErrorClass("postalCode", true)}
                        >
                          Postal Code *
                        </label>
                        <input
                          type="text"
                          id="postalCode"
                          name="postalCode"
                          aria-required="true"
                          autoComplete="postal-code"
                          className={handleErrorClass("postalCode", false)}
                          value={membership.address.postalCode}
                          onChange={_setAddressData}
                          disabled={membership.personIsComplete}
                        />
                        {membership.errorList.includes("postalCode") && (
                          <div
                            style={{
                              fontSize: "1.3rem",
                              color: "red",
                              fontWeight: "bold",
                              marginBottom: "1rem",
                            }}
                          >
                            Postal Code is required.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="input-wrapper m-b-0">
                      <input
                        type="checkbox"
                        id="socilitations"
                        name="mailExclude"
                        checked={membership.address.mailExclude}
                        onChange={_setAddressData}
                        disabled={false}
                      />
                      <label htmlFor="socilitations">
                        Exclude me from third-party solicitations
                      </label>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
