import React from "react";

const TextModal = ({ closeModal }) => {
  return (
    <div className="modal-body">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-11">
          <h3>Communications by Text</h3>
        </div>
        <div className="cell small-1">
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
      </div>
      <p>
        I agree to receive automated transactional and promotional text
        messages. Messages may include membership reminders, information related
        to a purchase and special offers. This agreement isn’t a condition of
        any purchase. Review the AANP{" "}
        <a
          href="https://www.aanp.org/aanp-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        (opens in a new window) and{" "}
        <a
          href="https://www.aanp.org/aanp-terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>{" "}
        (opens in a new window) .
      </p>
    </div>
  );
};

export default TextModal;
