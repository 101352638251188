import React from "react";

const NpiModal = ({ closeModal }) => {
  return (
    <div class="modal-body">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-11">
          <h3>About NPI Number</h3>
        </div>

        <div className="cell small-1">
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
      </div>
      <p>
        Starting in 2013, the Sunshine Act was implemented to ensure
        transparency of payments provided to practitioners from medical related
        companies such as drug manufacturers. For AANP, this means ensuring our
        exhibitors and sponsors have access to your NPI if they provide a gift
        or payment to you during an AANP event. For example, if you attend a
        sponsored luncheon, your lunch is considered and payment from the
        sponsor to you and must be reported. Another example would be a
        pharmaceutical sample given to you at an exhibitor's booth.
      </p>
      <p>
        In addition, starting in 2021, the payments you receive from these
        entities will be visible on the{" "}
        <a
          href="https://openpaymentsdata.cms.gov/"
          target="_blank"
          rel="noreferrer"
        >
          Open Payments Data site
        </a>{" "}
        to the general public, which includes a search by NPI.
      </p>
    </div>
  );
};

export default NpiModal;
