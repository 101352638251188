import React from "react";

const Spinner = (width) => {
  return (
    <div className="text-center">
      <img
        src="../images/aanp_spinner.svg"
        alt="Searching"
        style={{ position: "relative", width: { width } }}
      />
    </div>
  );
};

export default Spinner;
